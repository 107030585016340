<template>
  <div class="relative w-full h-64 overflow-hidden rounded-xl shadow-md bg-gray-100">
    <!-- Placeholder изображение при отсутствии изображений -->
    <div v-if="!props.images.length" class="absolute inset-0 transition-opacity duration-500">
      <img src="@/assets/img/noImg.png" alt="Image of Yacht" class="w-full h-full object-cover">
    </div>

    <!-- Карусель с изображениями -->
    <template v-if="props.images.length && !isChangingImages">
      <div
          v-for="(image, index) in props.images"
          :key="index"
          class="absolute inset-0 transition-opacity duration-500"
          :class="{ 'opacity-100': currentIndex === index, 'opacity-0': currentIndex !== index }"
      >
        <!-- Заглушка или анимация загрузки -->
        <div v-if="loadingStates[index]" class="absolute inset-0 flex items-center justify-center bg-gray-200">
          <svg class="animate-spin h-8 w-8 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
          </svg>
        </div>

        <!-- Изображение с отслеживанием загрузки -->
        <img
            v-if="isVisible(index)"
            :src="image"
            alt="Yacht Image"
            class="w-full h-full object-cover"
            @load="handleImageLoad(index)"
            @error="handleImageError(index)"
        />

        <!-- Левая кнопка навигации -->
        <button
            v-if="currentIndex > 0"
            @click="prevImage"
            class="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md hover:bg-gray-200"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
          </svg>
        </button>

        <!-- Правая кнопка навигации -->
        <button
            v-if="currentIndex < props.images.length - 1"
            @click="nextImage"
            class="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md hover:bg-gray-200"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>
    </template>

    <!-- Индикаторы текущего изображения -->
    <div v-if="props.images.length !== 1 && !isChangingImages" class="items-center absolute bottom-2 left-1/2 transform -translate-x-1/2 flex space-x-1">
      <span
          v-for="(indicator, i) in visibleIndicators"
          :key="i"
          class="rounded-full transition-all duration-300 ease-out"
          :class="indicatorSizeClass(indicator)"
      ></span>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, ref, watch } from 'vue';

const props = defineProps({
  images: {
    type: Array,
    default: () => []
  }
});

const currentIndex = ref(0);
const loadingStates = ref([]); // Массив состояний загрузки для каждого изображения
const isChangingImages = ref(false); // Флаг для отслеживания смены изображений

// Обновляем состояния загрузки при изменении списка изображений
watch(
    () => props.images,
    (newImages) => {
      if (newImages.length) {
        isChangingImages.value = true; // Устанавливаем флаг смены изображений
        setTimeout(() => {
          loadingStates.value = newImages.map(() => true);
          currentIndex.value = 0; // Сбрасываем индекс текущего изображения
          isChangingImages.value = false; // Снимаем флаг после обновления
        }, 100); // Небольшая задержка перед обновлением
      }
    },
    { immediate: true }
);

// Функция проверки видимости изображения
const isVisible = (index) => {
  return index === currentIndex.value || Math.abs(index - currentIndex.value) <= 1;
};

// Вычисляемый список видимых индикаторов
const visibleIndicators = computed(() => {
  const totalIndicators = props.images.length;
  let start = 0;

  if (currentIndex.value < 3) {
    start = 0;
  } else if (currentIndex.value > totalIndicators - 4) {
    start = totalIndicators - 7;
  } else {
    start = currentIndex.value - 3;
  }

  return Array.from({ length: Math.min(7, totalIndicators) }, (_, i) => i + start);
});

// Функция для определения размера и цвета индикатора
const indicatorSizeClass = (index) => {
  if (index === currentIndex.value) return 'w-3 h-3 bg-sky-900';
  if (Math.abs(index - currentIndex.value) === 1) return 'w-2 h-2 bg-sky-100';
  return 'w-2 h-2 bg-sky-100';
};

// Переход к следующему изображению
const nextImage = () => {
  currentIndex.value = (currentIndex.value + 1) % props.images.length;
};

// Переход к предыдущему изображению
const prevImage = () => {
  currentIndex.value = (currentIndex.value - 1 + props.images.length) % props.images.length;
};

// Обработка успешной загрузки изображения
const handleImageLoad = (index) => {
  loadingStates.value[index] = false; // Снимаем заглушку
};

// Обработка ошибки загрузки изображения
const handleImageError = (index) => {
  // Если изображение не загрузилось, можем задать заглушку или убрать ошибку
  loadingStates.value[index] = false;
};
</script>

<style scoped>
/* Добавляем анимацию для перехода изображений */
.transition-transform {
  transition: transform 0.3s ease-out;
}

/* Анимация спиннера */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.animate-spin {
  animation: spin 1s linear infinite;
}
</style>
