<script setup>
import {computed, onMounted, ref} from 'vue';
import StaticCarousel from "@/components/UI/StaticCarousel.vue";
import axios from "axios";
import store from "@/store";
import ModalComponent from "@/components/UI/ModalComponent.vue";
import {useRouter} from "vue-router";

const isFavModalOpen = ref(false);
const selectedYachtForFavorite = ref(null);
const numberOfFavorite = ref();
const vipYachts = ref([]);
const updatedYachts = ref([]);
const isLoading = ref(true); // Флаг загрузки
const getFavorite = computed(() => store.getters.getFavorite);

const loadYachts = async () => {
  try {
    const response = await axios.get(`https://api.knotzone.com/api/v1/yachts/for/main/page`);

    vipYachts.value = response.data.vipYachts;
    updatedYachts.value = response.data.updatedYachts;
  } catch (error) {
    console.error('Error loading main page yachts:', error);
  } finally {
    isLoading.value = false; // Снимаем флаг загрузки после завершения запроса
  }
};

const closeModal = () => {
  isFavModalOpen.value = false;
}

const favorite = (yacht) => {
  let isFav = false;
  if (getFavorite.value) {
    getFavorite.value.forEach(fav => {
      if (fav.yacht != null && fav.yacht.id === yacht.id) {
        isFav = true;
      }
    });
  }
  return isFav;
};

const checkBeforeClickOnFavorite = async (yacht) => {
  const getAuth = computed(() => store.getters.getAuth);
  const getUserId = computed(() => store.getters.getUserId);
  if (getAuth.value && favorite(yacht)) {
    const response = await axios.get(`https://api.knotzone.com/api/v1/favorite/exist/yacht/${yacht.id}/in/${getUserId.value}`);
    if (response.data.number === 0) {
      clickFavorite(yacht);
    } else {
      numberOfFavorite.value = response.data.number;
      isFavModalOpen.value = true;
      selectedYachtForFavorite.value = yacht;
    }
  } else {
    clickFavorite(yacht);
  }
};

const clickFavorite = (yacht) => {
  store.commit('editFavorite', yacht);
  isFavModalOpen.value = false;
};

const router = useRouter();

const generateLinkForYacht = (id) => {
  const link = router.resolve({ path: `/yachts/${id}` });
  return link.href;
};

// Вычисление времени обновления
const getTimeElapsed = (date) => {
  const currentTime = new Date();
  const timeDiffInSeconds = Math.floor((currentTime - new Date(date)) / 1000);

  if (timeDiffInSeconds < 60) {
    return 'Less than a minute ago';
  } else if (timeDiffInSeconds < 3600) {
    const minutes = Math.floor(timeDiffInSeconds / 60);
    return `${minutes} minutes ago`;
  } else if (timeDiffInSeconds < 3600 * 24) {
    const hours = Math.floor(timeDiffInSeconds / 3600);
    const minutes = Math.floor((timeDiffInSeconds % 3600) / 60);
    return `${hours} hours and ${minutes} minutes ago`;
  } else {
    const days = Math.floor(timeDiffInSeconds / (3600 * 24));
    return days > 1 ? `${days} days ago` : `${days} day ago`;
  }
};

// Установка состояния
const getCondition = (isNew) => (isNew ? 'NEW' : 'USED');

// Форматирование цены
const formatPrice = (value) => (value == null ? 'price is not specified' : value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."));

onMounted(() => {
  loadYachts();
});
</script>

<template>
  <!-- Recommends yachts -->
  <p class="font-knotzone-xbold text-1xl text-sky-900">Recommends yachts</p>
  <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4">
    <template v-if="isLoading">
      <!-- Скелетон-лоадеры -->
      <div v-for="index in 6" :key="index"
           class="rounded-xl shadow-md bg-white relative group overflow-hidden animate-pulse">
        <div class="w-full h-40 bg-gray-200"></div>
        <div class="mt-2 space-y-1 p-3">
          <div class="h-4 bg-gray-200 rounded w-3/4"></div>
          <div class="h-4 bg-gray-200 rounded w-1/2"></div>
          <div class="h-4 bg-gray-200 rounded w-1/4"></div>
        </div>
      </div>
    </template>
    <template v-else>
      <!-- Контент яхт -->
      <div
          v-for="(yacht, index) in vipYachts"
          :key="index"
          class="rounded-xl shadow-md bg-white relative group overflow-hidden"
      >
        <!-- Изображение яхты -->
        <div class="relative">
          <static-carousel :images="yacht.images" class="w-full h-40 object-cover" />
          <!-- Иконка избранного -->
          <div class="absolute top-1.5 right-1.5 p-0.5 shadow-sm">
            <button @click="checkBeforeClickOnFavorite(yacht)" class="bg-white rounded-lg p-1" :class="favorite(yacht) ? `bg-sky-900` : `bg-sky-100`">
              <img class="h-6 w-6" :src="favorite(yacht) ? '/heart.svg' : '/unHeart.svg'" alt="Favorite"
                   title="Favorite" />
            </button>
          </div>
        </div>
        <a
            :href="generateLinkForYacht(yacht.id)"
            target="_blank"
            rel="noopener noreferrer"
            class="router z-40"
            style="text-decoration: none !important;"
        >
          <!-- Описание яхты -->
          <div class="mt-2 space-y-1 p-3">
            <h3 :title="yacht.name" class="text-base font-knotzone-medium text-gray-800 truncate">{{ yacht.name }}</h3>
            <p class="text-sm font-knotzone-light text-gray-600">
              {{ yacht.country }}, {{ getCondition(yacht.isNew) }}
            </p>
            <p class="text-sm font-knotzone-light text-gray-600">{{ yacht.length }}m, {{ yacht.year }}</p>
            <p class="text-base font-knotzone-xbold font-bold text-gray-800 mt-1">€{{ formatPrice(yacht.priceEUR) }}</p>
          </div>
        </a>
      </div>
    </template>
  </div>

  <!-- Latest updated yachts -->
  <p class="font-knotzone-xbold text-1xl text-sky-900 mt-4">Latest updated yachts</p>
  <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4 relative">
    <template v-if="isLoading">
      <!-- Скелетон-лоадеры -->
      <div v-for="index in 6" :key="index"
           class="rounded-xl shadow-md bg-white relative group overflow-hidden animate-pulse">
        <div class="w-full h-40 bg-gray-200"></div>
        <div class="mt-2 space-y-1 p-3">
          <div class="h-4 bg-gray-200 rounded w-3/4"></div>
          <div class="h-4 bg-gray-200 rounded w-1/2"></div>
          <div class="h-4 bg-gray-200 rounded w-1/4"></div>
        </div>
      </div>
    </template>
    <template v-else>
      <!-- Контент яхт -->
      <div
          v-for="(yacht, index) in updatedYachts"
          :key="index"
          class="rounded-xl shadow-md bg-white relative group overflow-hidden"
      >
        <!-- Изображение яхты -->
        <div class="relative">
          <StaticCarousel :images="yacht.images" class="w-full h-40 object-cover" />
          <!-- Иконка избранного -->
          <div class="absolute top-1.5 right-1.5 p-0.5 shadow-sm">
            <button @click="checkBeforeClickOnFavorite(yacht)" class="bg-white rounded-lg p-1" :class="favorite(yacht) ? `bg-sky-900` : `bg-sky-100`">
              <img class="h-6 w-6" :src="favorite(yacht) ? '/heart.svg' : '/unHeart.svg'" alt="Favorite"
                   title="Favorite" />
            </button>
          </div>
        </div>
        <a
            :href="generateLinkForYacht(yacht.id)"
            target="_blank"
            rel="noopener noreferrer"
            class="router z-40"
            style="text-decoration: none !important;"
        >
          <!-- Описание яхты -->
          <div class="mt-2 space-y-1 p-3">
            <h3 :title="yacht.name" class="text-base font-knotzone-medium text-gray-800 truncate">{{ yacht.name }},
              {{ yacht.country }}</h3>
            <p class="text-sm font-knotzone-light text-gray-600">{{ yacht.length }}m</p>
            <p class="text-sm font-knotzone-light text-gray-600">{{ getTimeElapsed(yacht.lastUpdateDate) }}</p>
            <p class="text-base font-knotzone-xbold font-bold text-gray-800 mt-1">€{{ formatPrice(yacht.priceEUR) }}</p>
          </div>
        </a>
      </div>
    </template>
  </div>
  <ModalComponent :is-open="isFavModalOpen"
                  @modal-close="closeModal"
                  @submit.prevent="clickFavorite(selectedYachtForFavorite)"
                  name="first-modal">
    <template #header>
      <div class="text-center w-full">
        <h3>Add group</h3>
      </div>
    </template>
    <template #content>
      <form @submit.prevent="clickFavorite(selectedYachtForFavorite)">
        <p>This yacht has been added to {{ numberOfFavorite }} groups of favorites, do you really want to remove it
          from favorites?</p>
        <div class="flex space-x-2">
          <button
              class="flex-1 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
              type="submit">
            Yes
          </button>
          <button
              class="flex-1 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
              type="button"
              @click="closeModal">
            Cansel
          </button>
        </div>
      </form>
    </template>
    <template #footer>
    </template>
  </ModalComponent>
</template>

<style scoped>
/* Добавление эффекта анимации пульсации для скелетон-лоадеров */
.animate-pulse {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
</style>
