<script setup>
import {defineProps, defineEmits, ref} from "vue";
import {onClickOutside} from '@vueuse/core'

const props = defineProps({
  isOpen: {
    type: Boolean
  }
});

const emit = defineEmits(["modal-close", "submit"]);

const target = ref(null);

onClickOutside(target, () => emit('modal-close'));
</script>

<template>
  <div v-if="props.isOpen" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div class="bg-white w-full h-full max-w-lg p-6 rounded-lg shadow-lg flex flex-col">
      <div class="flex-shrink-0">
        <div class="modal-header">
          <slot name="header">default header</slot>
        </div>
      </div>

      <div class="flex-grow overflow-y-auto my-4">
        <div class="modal-body">
          <slot name="content">default content</slot>
        </div>
      </div>

      <div class="flex-shrink-0">
        <form>
          <slot name="footer"></slot>
        </form>
      </div>
    </div>
  </div>
</template>


<style scoped>
</style>