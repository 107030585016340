<template>
  <header>
    <div class="row w-full items-center mt-2 relative">
      <div class="col text-left w-auto">
        <router-link to="/"
                     @click="reloadPage"
                     class="ml-5 text-center items-end text-2xl col router font-knotzone-xbold text-sky-800"
        >
          Knotzone
        </router-link>
      </div>
      <div v-if="isMainPage  && isSearchFormVisible" class="col text-center">
        <button @click.prevent="isYachtSearch = true"
                :class="isYachtSearch ? 'font-knotzone-xbold' : 'font-knotzone-bk'"
                class="text-sky-800 text-1xl px-3 py-1 rounded-lg">Yacht
        </button>
        <button @click.prevent="isYachtSearch = false"
                :class="!isYachtSearch ? 'font-knotzone-xbold' : 'font-knotzone-bk'"
                class="text-sky-800 text-1xl px-3 py-1 rounded-lg">Catalog
        </button>
      </div>
      <div class="col flex justify-end items-start text-right">
        <div class="nav-item flex ">
          <div class="rounded-full w-min">
            <div class="mt-2.5 mb-2.5 ml-1.5 mr-1.5"> <!-- уменьшенные отступы -->
              <router-link :to="'/favorite'" class="router nav-link">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" role="presentation"
                     focusable="false"
                     class="block fill-current text-[#0C4A6E] h-6 w-6 stroke-2 overflow-visible">
                  <!-- уменьшенный размер SVG -->
                  <path
                      d="M16 28c7-4.73 14-10 14-17a6.98 6.98 0 0 0-7-7c-1.8 0-3.58.68-4.95 2.05L16 8.1l-2.05-2.05a6.98 6.98 0 0 0-9.9 0A6.98 6.98 0 0 0 2 11c0 7 7 12.27 14 17z"></path>
                </svg>
              </router-link>
            </div>
          </div>
          <div @click.stop="openDropdownProfMenu"
               class="rounded-full bg-white bg-opacity-95 border hover:bg-gray-200 w-auto relative p-2 hover:shadow-lg">
            <div class="flex items-center m-0.5">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" role="presentation"
                     focusable="false"
                     class="block fill-current text-[#0C4A6E] h-4 w-4 stroke-current stroke-[3] overflow-visible">
                  <g fill="none">
                    <path d="M2 16h28M2 24h28M2 8h28"></path>
                  </g>
                </svg>
              </div>
              <div class="w-5 ml-1">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" role="presentation"
                     focusable="false" class="block h-6 w-6 fill-current text-[#0C4A6E]">
                  <path
                      d="M16 .7C7.56.7.7 7.56.7 16S7.56 31.3 16 31.3 31.3 24.44 31.3 16 24.44.7 16 .7zm0 28c-4.02 0-7.6-1.88-9.93-4.81a12.43 12.43 0 0 1 6.45-4.4A6.5 6.5 0 0 1 9.5 14a6.5 6.5 0 0 1 13 0 6.51 6.51 0 0 1-3.02 5.5 12.42 12.42 0 0 1 6.45 4.4A12.67 12.67 0 0 1 16 28.7z"></path>
                </svg>
              </div>
            </div>
            <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transition opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
            >
              <div v-show="isOpenProf"
                   class="rounded-lg absolute bg-white bg-opacity-95 border border-t-0 top-12 right-0 w-auto z-50 text-left">
                <section class="py-2 border-b">
                  <div v-if="auth">
                    <div class="w-full mb-2 rounded-lg hover:bg-gray-300 px-3 py-1">
                      <router-link :to="'/profile'" class="ml-2 router nav-link">
                        Profile
                      </router-link>
                    </div>
                    <div class="mb-2 rounded-lg hover:bg-gray-300 px-3 py-1">
                      <router-link :to="'/catalog'" class="ml-2 router nav-link">
                        Catalog
                      </router-link>
                    </div>
                    <div class="mb-2 w-full rounded-lg hover:bg-gray-300 px-3 py-1">
                      <router-link :to="'/favorite'" class=" ml-2 router nav-link">
                        Favorite
                      </router-link>
                    </div>
                  </div>
                  <div v-if="!auth" class="w-full ">
                    <div class="w-full mb-2 rounded-lg hover:bg-gray-300 px-3 py-1">
                      <router-link :to="'/favorite'" class="ml-2 router nav-link">
                        Favorite
                      </router-link>
                    </div>
                  </div>
                </section>
                <section class="py-2 border-b">
                  <div v-if="!auth">
                    <div class="mb-2 rounded-lg hover:bg-gray-300 px-3 py-1">
                      <router-link :to="'/register'" class="ml-2 router nav-link">
                        Register
                      </router-link>
                    </div>
                    <div class="mb-2 rounded-lg hover:bg-gray-300 px-3 py-1">
                      <router-link :to="'/login'" class="ml-2 router nav-link">
                        Login
                      </router-link>
                    </div>
                  </div>
                  <div v-if="auth">
                    <div class="mb-2 rounded-lg hover:bg-gray-300 px-3 py-1">
                      <router-link :to="'/'" @click="logout" class="ml-2 nav-link router">
                        Logout
                      </router-link>
                    </div>
                  </div>
                </section>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <!--Search form-->
      <div class="mt-1" v-if="isMainPage && isSearchFormVisible">
        <div v-if="isYachtSearch" class="text-center">
          <div class="flex flex-col space-y-4 text-center items-center">
            <div class="block">
              <div class="flex border-b items-center bg-white rounded-lg shadow-lg shadow-sky-800 mx-auto">
                <div class="h-full flex flex-col text-left py-2 px-4 rounded-lg hover:bg-gray-100 group"
                     @click="focusYachtInput">
                  <div>
                    <label for="yacht" class="text-xs font-bold text-black">Yacht</label>
                    <br>
                    <input
                        v-model="yachtInput"
                        ref="yachtInputPlace"
                        id="yacht"
                        type="text"
                        placeholder="eg. model, make"
                        class="outline-none text-sm text-gray-700 group-hover:bg-gray-100 group-hover:text-gray-900"
                    />
                  </div>
                </div>
                <div class="border-l border-gray-300 h-8" />
                <div class="h-full h- flex flex-col text-left py-2 px-4 rounded-lg hover:bg-gray-100 group relative">

                  <button @click.stop="openDropdownLengthMenu" class="text-left">
                    <label @click.stop for="length" class="text-xs font-bold text-black">Length</label>
                    <br>
                    <input
                        v-model="lengthInput"
                        id="length"
                        type="text"
                        placeholder="Length (m)"
                        class="outline-none text-sm text-gray-700 group-hover:bg-gray-100 group-hover:text-gray-900"
                        readonly
                    />
                  </button>

                  <transition
                      enter-active-class="transition ease-out duration-100"
                      enter-from-class="transition opacity-0 scale-95"
                      enter-to-class="transform opacity-100 scale-100"
                      leave-active-class="transition ease-in duration-75"
                      leave-from-class="transform opacity-100 scale-100"
                      leave-to-class="transform opacity-0 scale-95"
                  >
                    <div
                        v-show="isOpenLength"
                        class="rounded-lg absolute bg-white bg-opacity-95 border mt-16 w-auto z-50 left-0"
                    >
                      <div class="p-4 bg-white rounded-lg shadow-md w-80">
                        <div class="text-sm font-semibold text-gray-700 mb-2">
                          Length <span class="text-gray-400">from | to</span>
                        </div>

                        <div class="flex items-center text-center space-x-2 mb-4">
                          <div class="relative flex items-center border">
                            <input
                                v-model="startLength"
                                type="number"
                                min="0"
                                @click.stop
                                placeholder="0"
                                class="pl-10 pr-2 py-1 w-28 text-sm border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-500"
                            />
                            <span class="absolute left-2 text-gray-500 text-sm">M</span>
                          </div>

                          <span class="text-sm text-gray-500">&nbsp;&nbsp;to&nbsp;&nbsp;</span>

                          <div class="relative flex items-center border">
                            <span class="absolute left-2 text-gray-500 text-sm">M</span>
                            <input
                                v-model="endLength"
                                type="number"
                                placeholder="0"
                                @click.stop
                                min="0"
                                class="pl-10 pr-2 py-1 w-28 text-sm border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-500"
                            />
                          </div>
                        </div>

                        <button
                            class="w-full py-2 text-white bg-sky-900 hover:bg-sky-700 rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-500"
                            @click="applyFilterLength"
                        >
                          Apply Filter
                        </button>
                      </div>
                    </div>
                  </transition>
                </div>
                <div class="border-l border-gray-300 h-8" />
                <div class="h-full h- flex flex-col text-left py-2 px-4 rounded-lg hover:bg-gray-100 group relative">
                  <button @click.stop="openDropdownPriceMenu" class="text-left">
                    <label @click.stop for="price" class="text-xs font-bold text-black">Price</label>
                    <br>
                    <input
                        v-model="priceInput"
                        id="price"
                        type="text"
                        placeholder="Price in EUR"
                        class="outline-none text-sm text-gray-700 group-hover:bg-gray-100 group-hover:text-gray-900"
                        readonly
                    />
                  </button>
                  <transition
                      enter-active-class="transition ease-out duration-100"
                      enter-from-class="transition opacity-0 scale-95"
                      enter-to-class="transform opacity-100 scale-100"
                      leave-active-class="transition ease-in duration-75"
                      leave-from-class="transform opacity-100 scale-100"
                      leave-to-class="transform opacity-0 scale-95"
                  >
                    <div
                        v-show="isOpenPrice"
                        class="rounded-lg absolute bg-white bg-opacity-95 border mt-16 w-auto z-50 right-0"
                    >
                      <div class="p-4 bg-white rounded-lg shadow-md w-80">
                        <div class="text-sm font-semibold text-gray-700 mb-2">
                          Price <span class="text-gray-400">from | to</span>
                        </div>

                        <div class="flex items-center text-center space-x-2 mb-4">
                          <div class="relative flex items-center border">
                            <input
                                v-model="startPrice"
                                type="number"
                                min="0"
                                @click.stop
                                placeholder="0"
                                class="pl-10 pr-2 py-1 w-28 text-sm border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-500"
                            />
                            <span class="absolute left-2 text-gray-500 text-sm">€</span>
                          </div>

                          <span class="text-sm text-gray-500">&nbsp;to&nbsp;&nbsp;</span>

                          <div class="relative flex items-center border">
                            <span class="absolute left-2 text-gray-500 text-sm">€</span>
                            <input
                                v-model="endPrice"
                                type="number"
                                @click.stop
                                placeholder="0"
                                min="0"
                                class="pl-10 pr-2 py-1 w-28 text-sm border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-500"
                            />
                          </div>
                        </div>

                        <button
                            class="w-full py-2 text-white bg-sky-900 hover:bg-sky-700 rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-500"
                            @click="applyFilterPrice"
                        >
                          Apply Filter
                        </button>
                      </div>
                    </div>
                  </transition>
                </div>
                <div class="border-l border-gray-300 h-8" />
                <div class="h-full flex flex-col text-left py-2 px-4 rounded-lg hover:bg-gray-100 group relative">
                  <button @click.stop="openLocationModal" class="text-left">
                    <label for="location" @click.stop class="text-xs font-bold text-black">Location</label>
                    <br>
                    <input
                        v-model="locationInput"
                        id="location"
                        type="text"
                        placeholder="Location"
                        class="outline-none text-sm text-gray-700 group-hover:bg-gray-100 group-hover:text-gray-900"
                        readonly
                    />
                  </button>
                  <transition
                      enter-active-class="transition ease-out duration-100"
                      enter-from-class="transition opacity-0 scale-95"
                      enter-to-class="transform opacity-100 scale-100"
                      leave-active-class="transition ease-in duration-75"
                      leave-from-class="transform opacity-100 scale-100"
                      leave-to-class="transform opacity-0 scale-95"
                  >
                    <div
                        v-show="isLocationOpen"
                        class="rounded-lg absolute bg-white bg-opacity-95 border mt-16 w-full z-50 left-0"
                    >
                      <div class="mx-auto">
                        <input
                            type="text"
                            v-model="locationMenuInput"
                            @input="fetchLocations"
                            class="w-full px-3 py-2 hover:border-t-0 border-b border-l border-r border-gray-300 rounded"
                            placeholder="Name of country"
                            @click.stop
                        />

                        <div
                            class="mt-2 max-h-72 overflow-y-auto"
                        >
                          <button
                              v-for="location in locations"
                              :key="location.id"
                              @click="chooseLocation(location)"
                              class="w-full text-left px-3 py-2 border-b border-gray-200 hover:bg-gray-100"
                          >
                            {{ location.nameCountry }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
                <div class="bg-opacity-100 px-2">
                  <button
                      @click="changePage"
                      class="rounded-lg bg-sky-900 hover:bg-sky-700 p-3 text-white flex items-center justify-center shadow-md"
                  >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                      <path
                          fill-rule="evenodd"
                          d="M12.9 14.32a8 8 0 111.414-1.415l4.387 4.388a1 1 0 01-1.415 1.415l-4.387-4.388zM8 14a6 6 0 100-12 6 6 0 000 12z"
                          clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!isYachtSearch" class="text-center">
          <div class="flex flex-col space-y-4 text-center items-center">
            <div class="block">
              <div class="flex border-b items-center bg-white rounded-lg shadow-lg shadow-sky-800 mx-auto">
                <div class="h-full flex flex-col text-left py-2 px-4 rounded-lg hover:bg-gray-100 group"
                     @click="focusYachtCatalogInput">
                  <div>
                    <label for="yachtCatalog" class="text-xs font-bold text-black">Yacht</label>
                    <br>
                    <input
                        v-model="yachtCatalogInput"
                        ref="yachtCatalogInputPlace"
                        id="yachtCatalog"
                        type="text"
                        placeholder="eg. model, make"
                        class="outline-none text-sm text-gray-700 group-hover:bg-gray-100 group-hover:text-gray-900"
                    />
                  </div>
                </div>
                <div class="border-l border-gray-300 h-8" />
                <div class="h-full h- flex flex-col text-left py-2 px-4 rounded-lg hover:bg-gray-100 group relative"
                disabled="true">

                  <button class="text-left">
                    <label for="length" class="text-xs font-bold text-gray-400">LAO</label>
                    <br>
                    <input
                        v-model="laoInput"
                        id="length"
                        type="text"
                        placeholder="LAO (ft/m)"
                        class="outline-none text-sm text-gray-700 group-hover:bg-gray-100 group-hover:text-gray-900"
                        readonly
                    />
                  </button>

                  <transition
                      enter-active-class="transition ease-out duration-100"
                      enter-from-class="transition opacity-0 scale-95"
                      enter-to-class="transform opacity-100 scale-100"
                      leave-active-class="transition ease-in duration-75"
                      leave-from-class="transform opacity-100 scale-100"
                      leave-to-class="transform opacity-0 scale-95"
                  >
                    <div
                        v-show="isOpenLAO"
                        class="rounded-lg absolute bg-white bg-opacity-95 border mt-16 w-auto z-50 left-0"
                    >
                      <div class="p-4 bg-white rounded-lg shadow-md w-80">
                        <div class="text-sm font-semibold text-gray-700 mb-2">
                          LAO <span class="text-gray-400">from | to</span>
                        </div>
                        <div class="flex items-center text-center space-x-2 mb-4">
                          <div class="relative flex items-center border rounded-lg">
                            <span
                                @click="isM = !isM"
                                class="border-b-4 border-sky-900 px-2 py-0.5 text-white text-sm bg-sky-900 cursor-pointer rounded-l-md">
                              {{ isM ? `M` : `FT` }}
                            </span>
                            <input
                                v-model="startLao"
                                type="number"
                                min="0"
                                placeholder="0"
                                class="pl-2 pr-2 py-1 w-full text-sm border-none rounded-r-md focus:outline-none focus:ring-1 focus:ring-sky-900"
                            />
                          </div>
                          <span class="text-sm text-gray-500">to</span>
                          <div class="relative flex items-center border rounded-lg">
                            <span
                                @click="isM = !isM"
                                class="border-b-4 border-sky-900 px-2 py-0.5 text-white text-sm bg-sky-900 cursor-pointer rounded-l-md">
                              {{ isM ? `M` : `FT` }}
                            </span>
                            <input
                                v-model="endLao"
                                type="number"
                                placeholder="0"
                                min="0"
                                class="pl-2 pr-2 py-1 w-full text-sm border-none rounded-r-md focus:outline-none focus:ring-1 focus:ring-sky-900"
                            />
                          </div>
                        </div>
                        <div class="flex space-x-2">
                          <button
                              class="w-1/3 py-2 text-sky-900 bg-white border border-sky-900 hover:bg-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-900"
                              @click="resetFilterLao"
                          >
                            Reset
                          </button>
                          <button
                              class="w-2/3 py-2 text-white bg-sky-900 hover:bg-sky-700 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-900"
                              @click="applyFilterLao"
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
                <div class="border-l border-gray-300 h-8" />
                <div class="h-full h- flex flex-col text-left py-2 px-4 rounded-lg hover:bg-gray-100 group relative"
                      disabled="true">
                  <button class="text-left">
                    <label for="firstBuild" class="text-xs font-bold text-gray-400">First build</label>
                    <br>
                    <input
                        v-model="firstBuildInput"
                        id="firstBuild"
                        type="text"
                        placeholder="First build"
                        class="outline-none text-sm text-gray-700 group-hover:bg-gray-100 group-hover:text-gray-900"
                        readonly
                    />
                  </button>
                  <transition
                      enter-active-class="transition ease-out duration-100"
                      enter-from-class="transition opacity-0 scale-95"
                      enter-to-class="transform opacity-100 scale-100"
                      leave-active-class="transition ease-in duration-75"
                      leave-from-class="transform opacity-100 scale-100"
                      leave-to-class="transform opacity-0 scale-95"
                  >
                    <div
                        v-show="isOpenFirstBuild"
                        class="rounded-lg absolute bg-white bg-opacity-95 border mt-16 w-auto z-50 right-0"
                    >
                      <div class="p-4 bg-white rounded-lg shadow-md w-80">
                        <div class="text-sm font-semibold text-gray-700 mb-2">
                          Year <span class="text-gray-400">from | to</span>
                        </div>

                        <div class="flex items-center text-center space-x-2 mb-4">
                          <div class="relative flex items-center border rounded-lg">
                            <input
                                @click.stop
                                v-model="startFirstBuild"
                                type="number"
                                min="0"
                                placeholder="0"
                                class="pl-10 pr-2 py-1 w-28 text-sm border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-900"
                            />
                            <span class="absolute left-2 text-gray-500 text-sm"></span>
                          </div>

                          <span class="text-sm text-gray-500">&nbsp;to&nbsp;&nbsp;</span>

                          <div class="relative flex items-center border rounded-lg">
                            <span class="absolute left-2 text-gray-500 text-sm"></span>
                            <input
                                @click.stop
                                v-model="endFirstBuild"
                                type="number"
                                placeholder="0"
                                min="0"
                                class="pl-10 pr-2 py-1 w-28 text-sm border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-900"
                            />
                          </div>
                        </div>

                        <button
                            class="w-full py-2 text-white bg-sky-900 hover:bg-sky-700 rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-500"
                            @click="applyFilterFirstBuild"
                        >
                          Apply Filter
                        </button>
                      </div>
                    </div>
                  </transition>
                </div>
                <div class="border-l border-gray-300 h-8" />
                <div class="h-full flex flex-col text-left py-2 px-4 rounded-lg hover:bg-gray-100 group"
                     disabled="true">
                  <div>
                    <label for="hullType" class="text-xs font-bold text-gray-400">Hull Type</label>
                    <br>
                    <input
                        v-model="hullTypeInput"
                        ref="hullTypeInputPlace"
                        id="hullType"
                        type="text"
                        placeholder="Hull Type"
                        class="outline-none text-sm text-gray-700 group-hover:bg-gray-100 group-hover:text-gray-900"
                    />
                  </div>
                </div>
                <div class="bg-opacity-100 px-2">
                  <button @click.prevent="performSearch"
                      class="rounded-lg bg-sky-900 hover:bg-sky-700 p-3 text-white flex items-center justify-center shadow-md"
                  >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                      <path
                          fill-rule="evenodd"
                          d="M12.9 14.32a8 8 0 111.414-1.415l4.387 4.388a1 1 0 01-1.415 1.415l-4.387-4.388zM8 14a6 6 0 100-12 6 6 0 000 12z"
                          clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isMainPage && !isSearchFormVisible" class="col flex items-center justify-center w-full">
      <button @click.stop="openModalHeader"
              class="mt-2 w-full flex items-center px-4 py-2 border border-gray-200 rounded-full shadow-sm hover:shadow-md hover:bg-sky-50 transition duration-200">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-sky-900 hover:text-white" fill="currentColor"
             viewBox="0 0 20 20">
          <path fill-rule="evenodd"
                d="M12.9 14.32a8 8 0 111.414-1.415l4.387 4.388a1 1 0 01-1.415 1.415l-4.387-4.388zM8 14a6 6 0 100-12 6 6 0 000 12z"
                clip-rule="evenodd" />
        </svg>

        <span class="ml-2 text-sky-900 hover:text-white">Search</span>
      </button>
    </div>
  </header>
  <FullModalComponent :is-open="isModalHeaderOpen"
                      @modal-close="closeModalHeader"
                      name="first-modal">
    <!-- Header -->
    <template #header>
      <div class="flex items-center justify-between pb-4">
        <button @click="closeModalHeader" class="text-gray-500 hover:text-gray-700">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        <div class="text-sky-900 absolute left-1/2 transform -translate-x-1/2 flex space-x-6 text-center">
          <button
              @click="isYachtSearch = !isYachtSearch"
              :class="isYachtSearch ?
                `font-knotzone-xbold border-b-2 border-black` :
                `font-knotzone-bk`">
            Yacht
          </button>
          <button
              @click="isYachtSearch = !isYachtSearch"
              :class="!isYachtSearch ?
                `font-knotzone-xbold border-b-2 border-black` :
                `font-knotzone-bk`">
            Catalog
          </button>
        </div>
      </div>
    </template>

    <!-- Content -->
    <template #content>
      <div v-if="isYachtSearch" class="space-y-4 relative">
        <!-- Поле Yacht -->
        <div class="flex flex-col hover:bg-gray-100">
          <input
              v-model="yachtInput"
              id="yacht"
              type="text"
              placeholder="Yacht - eg. model, make"
              class="w-full py-3 px-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-900"
          />
        </div>

        <!-- Поле Length -->
        <div class="flex flex-col hover:bg-gray-100">
          <input
              v-if="!showLengthFields"
              v-model="lengthInput"
              id="length"
              type="text"
              placeholder="Length (m)"
              class="w-full py-3 px-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-900"
              @focus="openLengthFields"
          />
          <div v-if="showLengthFields" class="w-full inset-0 flex items-center justify-center z-10">
            <div class="w-full bg-white p-6 rounded-lg shadow-lg">
              <div class="text-sm font-semibold text-gray-700 mb-2">
                Length <span class="text-gray-400">from | to</span>
              </div>

              <div class="flex items-center text-center space-x-2 mb-4">
                <div class="relative flex items-center border">
                  <input
                      v-model="startLength"
                      type="number"
                      min="0"
                      placeholder="0"
                      class="pl-10 pr-2 py-1 w-full text-sm border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-900"
                  />
                  <span class="absolute left-2 text-gray-500 text-sm">M</span>
                </div>

                <span class="text-sm text-gray-500">to</span>

                <div class="relative flex items-center border">
                  <span class="absolute left-2 text-gray-500 text-sm">M</span>
                  <input
                      v-model="endLength"
                      type="number"
                      placeholder="0"
                      min="0"
                      class="pl-10 pr-2 py-1 w-full text-sm border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-900"
                  />
                </div>
              </div>

              <div class="flex space-x-2">
                <button
                    class="w-1/3 py-2 text-sky-900 bg-white border border-sky-900 hover:bg-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-900"
                    @click="resetFilterLength"
                >
                  Reset
                </button>
                <button
                    class="w-2/3 py-2 text-white bg-sky-900 hover:bg-sky-700 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-900"
                    @click="applyFilterLength"
                >
                  Apply Filter
                </button>
              </div>
            </div>
          </div>

        </div>

        <!-- Поле Price -->
        <div class="flex flex-col hover:bg-gray-100">
          <input
              v-if="!showPriceFields"
              v-model="priceInput"
              id="price"
              type="text"
              placeholder="Price in EUR"
              class="w-full py-3 px-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-900"
              @focus="openPriceFields"
          />
          <div v-if="showPriceFields" class="w-full inset-0 flex items-center justify-center z-10">
            <div class="w-full bg-white p-6 rounded-lg shadow-lg">
              <div class="text-sm font-semibold text-gray-700 mb-2">
                Price <span class="text-gray-400">from | to</span>
              </div>

              <div class="flex items-center text-center space-x-2 mb-4">
                <div class="relative flex items-center border">
                  <input
                      v-model="startPrice"
                      type="number"
                      min="0"
                      placeholder="0"
                      class="pl-10 pr-2 py-1 w-full text-sm border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-900"
                  />
                  <span class="absolute left-2 text-gray-500 text-sm">€</span>
                </div>

                <span class="text-sm text-gray-500">to</span>

                <div class="relative flex items-center border">
                  <span class="absolute left-2 text-gray-500 text-sm">€</span>
                  <input
                      v-model="endPrice"
                      type="number"
                      placeholder="0"
                      min="0"
                      class="pl-10 pr-2 py-1 w-full text-sm border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-900"
                  />
                </div>
              </div>

              <div class="flex space-x-2">
                <button
                    class="w-1/3 py-2 text-sky-900 bg-white border border-sky-900 hover:bg-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-900"
                    @click="resetFilterPrice"
                >
                  Reset
                </button>
                <button
                    class="w-2/3 py-2 text-white bg-sky-900 hover:bg-sky-700 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-900"
                    @click="applyFilterPrice"
                >
                  Apply Filter
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Поле Location -->
        <div class="flex flex-col hover:bg-gray-100">
          <input
              v-if="!showLocationFields"
              v-model="locationInput"
              id="location"
              type="text"
              placeholder="Location"
              class="w-full py-3 px-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-900"
              @focus="openLocationFields"
          />
          <div v-if="showLocationFields">
            <div>
              <input
                  type="text"
                  v-model="locationMenuInput"
                  @input="fetchLocations"
                  class="w-full py-3 px-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-900"
                  placeholder="Name of country"
                  @click.stop
              />

              <div
                  class="mt-2 max-h-72 overflow-y-auto"
              >
                <button
                    v-for="location in locations"
                    :key="location.id"
                    @click="chooseLocation(location)"
                    class="w-full text-left px-3 py-2 border-b border-gray-200 hover:bg-gray-100"
                >
                  {{ location.nameCountry }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isYachtSearch" class="space-y-4">
        <!-- Поле Yacht -->
        <div class="flex flex-col hover:bg-gray-100">
          <input
              v-model="yachtCatalogInput"
              id="yacht"
              type="text"
              placeholder="Yacht - eg. model, make"
              class="w-full py-3 px-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-900"
          />
        </div>

        <!-- Поле LAO -->
        <div class="flex flex-col hover:bg-gray-100" v-if="false">
          <input
              v-if="!showLaoFields"
              v-model="laoInput"
              id="lao"
              type="text"
              placeholder="LAO (m/ft)"
              class="w-full py-3 px-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-900"
              @focus="openLaoFields"
          />
          <div v-if="showLaoFields" class="w-full inset-0 flex items-center justify-center z-10">
            <div class="w-full bg-white p-6 rounded-lg shadow-lg">
              <div class="text-sm font-semibold text-gray-700 mb-2">
                LAO <span class="text-gray-400">from | to</span>
              </div>

              <div class="flex items-center text-center align-text-bottom space-x-2 mb-4">
                <div class="relative flex items-center border border-gray-300 rounded-md">
                  <span
                      @click="isM = !isM"
                      class="border-b-4 border-sky-900 px-2 py-0.5 text-white text-sm bg-sky-900 cursor-pointer rounded-l-md">
                    {{ isM ? `M` : `FT` }}
                  </span>
                  <input
                      v-model="startLao"
                      type="number"
                      min="0"
                      placeholder="0"
                      class="pl-2 pr-2 py-1 w-full text-sm border-none rounded-r-md focus:outline-none focus:ring-1 focus:ring-sky-900"
                  />
                </div>
                <span class="text-sm text-gray-500">to</span>
                <div class="relative flex items-center border border-gray-300 rounded-md">
                  <span
                      @click="isM = !isM"
                      class="border-b-4 border-sky-900 px-2 py-0.5 text-white text-sm bg-sky-900 cursor-pointer rounded-l-md">
                    {{ isM ? `M` : `FT` }}
                  </span>
                  <input
                      v-model="endLao"
                      type="number"
                      placeholder="0"
                      min="0"
                      class="pl-2 pr-2 py-1 w-full text-sm border-none rounded-r-md focus:outline-none focus:ring-1 focus:ring-sky-900"
                  />
                </div>
              </div>

              <div class="flex space-x-2">
                <button
                    class="w-1/3 py-2 text-sky-900 bg-white border border-sky-900 hover:bg-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-900"
                    @click="resetFilterLao"
                >
                  Reset
                </button>
                <button
                    class="w-2/3 py-2 text-white bg-sky-900 hover:bg-sky-700 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-900"
                    @click="applyFilterLao"
                >
                  Apply Filter
                </button>
              </div>
            </div>
          </div>

        </div>

        <!-- Поле First build -->
        <div class="flex flex-col hover:bg-gray-100" v-if="false">
          <input
              v-if="!showFirstBuildFields"
              v-model="firstBuildInput"
              id="price"
              type="text"
              placeholder="First build"
              class="w-full py-3 px-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-900"
              @focus="openFirstBuildFields"
          />
          <div v-if="showFirstBuildFields" class="w-full inset-0 flex items-center justify-center z-10">
            <div class="w-full bg-white p-6 rounded-lg shadow-lg">
              <div class="text-sm font-semibold text-gray-700 mb-2">
                Year <span class="text-gray-400">from | to</span>
              </div>

              <div class="flex items-center text-center space-x-2 mb-4">
                <div class="relative flex items-center border">
                  <input
                      v-model="startFirstBuild"
                      type="number"
                      min="0"
                      placeholder="0"
                      class="pl-10 pr-2 py-1 w-full text-sm border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-900"
                  />
                  <span class="absolute left-2 text-gray-500 text-sm"></span>
                </div>

                <span class="text-sm text-gray-500">to</span>

                <div class="relative flex items-center border">
                  <span class="absolute left-2 text-gray-500 text-sm"></span>
                  <input
                      v-model="endFirstBuild"
                      type="number"
                      placeholder="0"
                      min="0"
                      class="pl-10 pr-2 py-1 w-full text-sm border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-900"
                  />
                </div>
              </div>

              <div class="flex space-x-2">
                <button
                    class="w-1/3 py-2 text-sky-900 bg-white border border-sky-900 hover:bg-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-900"
                    @click="resetFilterFirstBuild"
                >
                  Reset
                </button>
                <button
                    class="w-2/3 py-2 text-white bg-sky-900 hover:bg-sky-700 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-900"
                    @click="applyFilterFirstBuild"
                >
                  Apply Filter
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Поле Hull Type -->
        <div class="flex flex-col hover:bg-gray-100" v-if="false">
          <input
              v-if="!showHullTypeFields"
              v-model="hullTypeInput"
              id="hullType"
              type="text"
              placeholder="Hull Type"
              class="w-full py-3 px-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-900"
              @focus="openHullTypeFields"
          />
          <div v-if="showHullTypeFields">
            <div>
              <input
                  type="text"
                  v-model="hullTypeMenuInput"
                  @input="fetchHullType"
                  class="w-full py-3 px-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-900"
                  placeholder="Hull Type"
                  @click.stop
              />

              <div
                  class="mt-2 max-h-72 overflow-y-auto"
              >
                <button
                    v-for="catalog in catalogs"
                    :key="catalog.id"
                    @click="chooseHullType(catalog)"
                    class="w-full text-left px-3 py-2 border-b border-gray-200 hover:bg-gray-100"
                >
                  {{ catalog.hullType }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <!-- Footer -->
    <template #footer>
      <div class="mt-6 flex justify-between items-center px-4 py-2 border-t">
        <!-- Кнопка "Clear all" слева -->
        <button @click.prevent="clearFilters" class="text-sky-900">Clear all</button>

        <button
            v-if="isYachtSearch"
            @click.prevent="changePage"
            class="flex items-center px-6 py-2 bg-sky-900 text-white rounded-full hover:bg-sky-700 ml-auto">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" class="w-5 h-5 mr-2">
            <path fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1111.46 2.88l4.87 4.87a1 1 0 01-1.42 1.42l-4.87-4.87A6 6 0 012 8z"
                  clip-rule="evenodd" />
          </svg>
          Search
        </button>
        <button
            v-if="!isYachtSearch"
            @click.prevent="performSearch"
            class="flex items-center px-6 py-2 bg-sky-900 text-white rounded-full hover:bg-sky-700 ml-auto">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" class="w-5 h-5 mr-2">
            <path fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1111.46 2.88l4.87 4.87a1 1 0 01-1.42 1.42l-4.87-4.87A6 6 0 012 8z"
                  clip-rule="evenodd" />
          </svg>
          Search
        </button>
      </div>
    </template>
  </FullModalComponent>
</template>

<script>
import {computed, onBeforeUnmount, onMounted, ref} from "vue";
import axios from "axios";
import {useStore} from "vuex";
import router from "@/router";
import FullModalComponent from "@/components/UI/FullModalComponent.vue";
import {useRouter} from "vue-router";

export default {
  name: 'marine-header',
  components: {FullModalComponent},
  setup() {
    const store = useStore();

    const auth = computed(() => store.state.auth);

    const isOpenProf = ref(false);

    const isYachtSearch = ref(true);

    const logout = async () => {
      await axios.post("/auth/logout", {}, {withCredentials: true})

      axios.defaults.headers.common['Authorization'] = ``;

      await store.dispatch('setAuth', false);
      await store.dispatch('clearLocalDate');
    }

    const reloadPage = async () => {
      await router.push('/');
      await window.location.reload();
    }

    const performSearch = async () => {
      isModalHeaderOpen.value = false;
      if (yachtCatalogInput.value !== '' && yachtCatalogInput.value) {
        await router.push(`/catalog/q/${yachtCatalogInput.value}`);
      } else {
        await router.push(`/catalog`);
      }
    }

    const applyFilterLength = () => {
      if (isM.value) {
        if ((startLength.value == null || startLength.value === 0)
            && (endLength.value == null || endLength.value === 0)) {
          lengthInput.value = null;
        } else if (startLength.value == null || startLength.value === 0) {
          lengthInput.value = `To ${endLength.value || ''}m`;
        } else if (endLength.value == null || endLength.value === 0) {
          lengthInput.value = `From ${startLength.value || ''}m`;
        } else {
          lengthInput.value = `${startLength.value || ''}m - ${endLength.value || ''}m`;
        }
      } else {
        if ((startLength.value == null || startLength.value === 0)
            && (endLength.value == null || endLength.value === 0)) {
          lengthInput.value = null;
        } else if (startLength.value == null || startLength.value === 0) {
          lengthInput.value = `To ${endLength.value || ''}ft`;
        } else if (endLength.value == null || endLength.value === 0) {
          lengthInput.value = `From ${startLength.value || ''}ft`;
        } else {
          lengthInput.value = `${startLength.value || ''}ft - ${endLength.value || ''}ft`;
        }
      }
      isOpenLength.value = false;
      showLengthFields.value = false;
    };

    const applyFilterPrice = () => {
      if ((startPrice.value == null || startPrice.value === 0)
          && (endPrice.value == null || endPrice.value === 0)) {
        priceInput.value = null;
      } else if (startPrice.value == null || startPrice.value === 0) {
        priceInput.value = `To ${endPrice.value || ''}€`;
      } else if (endPrice.value == null || endPrice.value === 0) {
        priceInput.value = `From ${startPrice.value || ''}€`;
      } else {
        priceInput.value = `${startPrice.value || ''}€ - ${endPrice.value || ''}€`;
      }
      isOpenPrice.value = false;
      showPriceFields.value = false;
    };

    const isMainPage = ref(true);

    /* yacht search */

    const yachtInputPlace = ref(null);

    const yachtInput = ref(null);

    const lengthInput = ref(null);

    const priceInput = ref(null);

    const locationInput = ref(null);

    const isOpenLength = ref(false);

    const startLength = ref(null);

    const endLength = ref(null);

    const isOpenPrice = ref(false);

    const startPrice = ref(null);

    const endPrice = ref(null);

    /* catalog search */

    /* Yacht start*/

    const yachtCatalogInputPlace = ref(null);

    const yachtCatalogInput = ref(null);

    const focusYachtCatalogInput = () => {
      if (yachtCatalogInputPlace.value) {
        yachtCatalogInputPlace.value.focus();
      }
    };

    /* Yacht end */

    /* LAO start*/

    const isOpenLAO = ref(false);

    const startLao = ref(null);

    const endLao = ref(null);

    const laoInput = ref(null);

    const openDropdownLAOMenu = () => {
      isOpenLAO.value = !isOpenLAO.value;
      isOpenProf.value = false;
      isOpenFirstBuild.value = false;
    }

    const applyFilterLao = () => {
      if (isM.value) {
        if ((startLao.value == null || startLao.value === 0 || startLao.value === '')
            && (endLao.value == null || endLao.value === 0 || endLao.value === '')) {
          laoInput.value = null;
        } else if (startLao.value == null || startLao.value === 0 || startLao.value === '') {
          laoInput.value = `To ${endLao.value || ''}m`;
        } else if (endLao.value == null || endLao.value === 0 || endLao.value === '') {
          laoInput.value = `From ${startLao.value || ''}m`;
        } else {
          laoInput.value = `${startLao.value || ''}m - ${endLao.value || ''}m`;
        }
      } else {
        if ((startLao.value == null || startLao.value === 0 || startLao.value === '')
            && (endLao.value == null || endLao.value === 0 || endLao.value === '')) {
          laoInput.value = null;
        } else if (startLao.value == null || startLao.value === 0 || startLao.value === '') {
          laoInput.value = `To ${endLao.value || ''}ft`;
        } else if (endLao.value == null || endLao.value === 0 || endLao.value === '') {
          laoInput.value = `From ${startLao.value || ''}ft`;
        } else {
          laoInput.value = `${startLao.value || ''}ft - ${endLao.value || ''}ft`;
        }
      }
      isOpenLength.value = false;
      showLaoFields.value = false;
      isOpenLAO.value = false;
    };

    /* LAO end*/

    /* First build start*/

    const firstBuildInput = ref(null)

    const isOpenFirstBuild = ref(false);

    const startFirstBuild = ref(null);

    const endFirstBuild = ref(null);

    const openDropdownFirstBuildMenu = () => {
      isOpenFirstBuild.value = !isOpenFirstBuild.value;
      isOpenProf.value = false;
      isOpenLAO.value = false;
    }

    const applyFilterFirstBuild = () => {
      if ((startFirstBuild.value == null || startFirstBuild.value === 0)
          && (endFirstBuild.value == null || endFirstBuild.value === 0)) {
        firstBuildInput.value = null;
      } else if (startFirstBuild.value == null || startFirstBuild.value === 0) {
        firstBuildInput.value = `To ${endFirstBuild.value || ''} year`;
      } else if (endFirstBuild.value == null || endFirstBuild.value === 0) {
        firstBuildInput.value = `From ${startFirstBuild.value || ''} year`;
      } else {
        firstBuildInput.value = `${startFirstBuild.value || ''} - ${endFirstBuild.value || ''} years`;
      }
      isOpenFirstBuild.value = false;
      showFirstBuildFields.value = false;
    };

    /* First build end*/

    /* Hull type start*/

    const hullTypeInput = ref(null);

    const hullTypeInputPlace = ref(null);

    const focusHullTypeInput = () => {
      if (hullTypeInputPlace.value) {
        hullTypeInputPlace.value.focus();
      }
    };

    /* Hull type end*/

    const focusYachtInput = () => {
      if (yachtInputPlace.value) {
        yachtInputPlace.value.focus();
      }
    };

    const openDropdownPriceMenu = () => {
      isOpenPrice.value = !isOpenPrice.value;
      isOpenProf.value = false;
      isOpenLength.value = false;
      isLocationOpen.value = false;
    }

    const openDropdownLengthMenu = () => {
      isOpenLength.value = !isOpenLength.value;
      isOpenProf.value = false;
      isOpenPrice.value = false;
      isLocationOpen.value = false;
    }

    const openDropdownProfMenu = () => {
      isOpenProf.value = !isOpenProf.value;
      isOpenPrice.value = false;
      isOpenLength.value = false;
      isLocationOpen.value = false;
    }

    /* Location menu start */

    const isLocationOpen = ref(false);

    const locations = ref([]);

    const locationMenuInput = ref('');

    const closeLocationModal = () => {
      isLocationOpen.value = false;
    }

    const openLocationModal = () => {
      if (locationInput.value != null)
        locationMenuInput.value = locationInput.value;
      loadLocations();
      isLocationOpen.value = !isLocationOpen.value;
      isOpenProf.value = false;
      isOpenPrice.value = false;
      isOpenLength.value = false;
    }

    const loadLocations = async () => {
      try {
        const data = {
          nameCountry: locationMenuInput.value
        };

        const response = await axios.post(
            `https://api.knotzone.com/api/v1/location/get/like/name`, data);

        locations.value = response.data.locations;
      } catch (error) {
        console.error('Error loading locations: ', error);
      }
    };

    const fetchLocations = async () => {
      await loadLocations();
    };

    const chooseLocation = (location) => {
      locationInput.value = location.nameCountry;
      locationMenuInput.value = '';
      locations.value = [];
      isLocationOpen.value = false;
      showLocationFields.value = false;
    };

    /* Location modal end */

    /* Visible search form start */

    const isSearchFormVisible = ref(true);

    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
      checkYachtSearchVisibility();
    };

    const checkYachtSearchVisibility = () => {
      isSearchFormVisible.value = windowWidth.value >= 1050;
    };

    const openSearchButton = () => {
      // Логика для открытия формы или поиска
    };

    onMounted(() => {
      window.addEventListener('resize', handleResize);
      checkYachtSearchVisibility();
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', handleResize);
    });

    /* Visible search form end */

    /* mobile modal window start */

    const isModalHeaderOpen = ref(false);

    const closeModalHeader = () => {
      isModalHeaderOpen.value = false;
    }

    const openModalHeader = () => {
      isModalHeaderOpen.value = true;
    }

    const showLengthFields = ref(false);

    const openLengthFields = () => {
      showLengthFields.value = true;
      showPriceFields.value = false;
      showLocationFields.value = false;
    }

    const resetFilterLength = () => {
      startLength.value = 0;
      endLength.value = 0;
      lengthInput.value = '';
      showLengthFields.value = false;
    };

    const showLaoFields = ref(false);

    const openLaoFields = () => {
      showLaoFields.value = true;
      showFirstBuildFields.value = false
    }

    const resetFilterLao = () => {
      startLao.value = 0;
      endLao.value = 0;
      laoInput.value = '';
      isOpenLAO.value = false;
      showLaoFields.value = false;
    };

    const showPriceFields = ref(false);

    const openPriceFields = () => {
      showPriceFields.value = true;
      showLengthFields.value = false;
      showLocationFields.value = false;
    }

    const resetFilterPrice = () => {
      startPrice.value = 0;
      endPrice.value = 0;
      priceInput.value = '';
      showPriceFields.value = false;
    };

    const showLocationFields = ref(false);

    const openLocationFields = () => {
      loadLocations();
      locationMenuInput.value = locationInput.value;
      showLocationFields.value = true;
      showPriceFields.value = false;
      showLengthFields.value = false;
    }

    const clearFilters = () => {
      yachtInput.value = '';
      lengthInput.value = '';
      priceInput.value = '';
      locationInput.value = '';
      showLocationFields.value = false;
      showPriceFields.value = false;
      showLengthFields.value = false;

      laoInput.value = '';
      firstBuildInput.value = '';
      hullTypeInput.value = '';
      showHullTypeFields.value = false;
      showLaoFields.value = false;
      showFirstBuildFields.value = false;
    }

    const isM = ref(true);

    /* First build */

    const showFirstBuildFields = ref(false);

    const openFirstBuildFields = () => {
      showFirstBuildFields.value = true;
      showLaoFields.value = false;
    }

    const resetFilterFirstBuild = () => {
      startFirstBuild.value = 0;
      endFirstBuild.value = 0;
      firstBuildInput.value = '';
      showFirstBuildFields.value = false;
    };

    const showHullTypeFields = ref(false);

    const openHullTypeFields = () => {
      loadHullType();
      hullTypeMenuInput.value = hullTypeInput.value;
      showHullTypeFields.value = true;
      showLaoFields.value = false;
      showFirstBuildFields.value = false;
    }

    const hullTypeMenuInput = ref('');

    const closeHullTypeModal = () => {
      isLocationOpen.value = false;
    }

    const openHullTypeModal = () => {
      if (hullTypeInput.value != null)
        hullTypeMenuInput.value = hullTypeInput.value;
      loadHullType();
      isLocationOpen.value = !isLocationOpen.value;
      isOpenProf.value = false;
      isOpenLAO.value = false;
      isOpenFirstBuild.value = false;
    }

    const catalogs = ref([]);

    const loadHullType = async () => {
      try {
        const data = {
          hullType: hullTypeMenuInput.value
        };

        console.log('hullTypeMenuInput.value')
        console.log(hullTypeMenuInput.value)

        const response = await axios.post(
            `https://api.knotzone.com/api/v1/catalog/get/like/name`, data);

        catalogs.value = response.data.catalogs;
      } catch (error) {
        console.error('Error loading catalog\'s hull types: ', error);
      }
    };

    const fetchHullType = async () => {
      await loadHullType();
    };

    const chooseHullType = (hullType) => {
      hullTypeInput.value = hullType.nameCountry;
      hullTypeMenuInput.value = '';
      locations.value = [];
      isLocationOpen.value = false;
      showLocationFields.value = false;
    };

    const router = useRouter();

    const changePage = () => {
      isModalHeaderOpen.value = false;
      if (!isNullOrEmpty(getFilterRout())) {
        router.push(`/yachts/filters/${getFilterRout()}`);
      } else {
        router.push(`/yachts/sort-by/recentlyUpdatedDescending/`);
      }
    };

    const isNullOrEmpty = (value) => {
      return value === null || value === '';
    }

    const getFilterRout = () => {
      const filter  = {
        make: yachtInput.value,
        minLength: startLength.value,
        maxLength: endLength.value,
        minPrice: startPrice.value,
        maxPrice: endPrice.value,
        location: locationInput.value
      }

      let result = '';
      for (const key in filter) {
        if (!isNullOrEmpty(filter[key])) {
          if (key === 'condition' && filter[key] === false) {
            continue;
          }
          result += `${key}=${filter[key].toString()}&`;
        }
      }

      // Убираем последний символ "&" из результата, если он есть
      if (result.endsWith('&')) {
        result = result.slice(0, -1);
      }

      return result;
    }

    return {
      performSearch,
      isNullOrEmpty,
      getFilterRout,
      changePage,
      catalogs,
      openHullTypeModal,
      closeHullTypeModal,
      hullTypeMenuInput,
      loadHullType,
      fetchHullType,
      chooseHullType,
      openHullTypeFields,
      showHullTypeFields,
      showFirstBuildFields,
      resetFilterFirstBuild,
      openFirstBuildFields,
      auth,
      clearFilters,
      resetFilterLao,
      openLaoFields,
      isM,
      openLengthFields,
      openLocationFields,
      openPriceFields,
      showPriceFields,
      showLocationFields,
      resetFilterPrice,
      resetFilterLength,
      showLengthFields,
      showLaoFields,
      closeModalHeader,
      openModalHeader,
      isModalHeaderOpen,
      isSearchFormVisible,
      logout,
      openSearchButton,
      checkYachtSearchVisibility,
      handleResize,
      reloadPage,
      isOpenProf,
      isYachtSearch,
      focusYachtInput,
      yachtInputPlace,
      lengthInput,
      locationInput,
      yachtInput,
      priceInput,
      isOpenLength,
      isOpenPrice,
      applyFilterLength,
      applyFilterPrice,
      startLength,
      endLength,
      startPrice,
      endPrice,
      openDropdownPriceMenu,
      openDropdownLengthMenu,
      openDropdownProfMenu,
      isMainPage,
      yachtCatalogInputPlace,
      yachtCatalogInput,
      focusYachtCatalogInput,
      openDropdownLAOMenu,
      isOpenLAO,
      startLao,
      endLao,
      applyFilterLao,
      laoInput,
      openDropdownFirstBuildMenu,
      isOpenFirstBuild,
      firstBuildInput,
      startFirstBuild,
      endFirstBuild,
      applyFilterFirstBuild,
      focusHullTypeInput,
      hullTypeInputPlace,
      hullTypeInput,
      isLocationOpen,
      closeLocationModal,
      openLocationModal,
      loadLocations,
      locations,
      fetchLocations,
      chooseLocation,
      locationMenuInput,
      windowWidth
    }
  },
  mounted() {
    window.addEventListener('click', event => {
      if (!this.$el.contains(event.target)) {
        this.isOpenProf = false;
        this.isOpenLength = false;
        this.isOpenPrice = false;
        this.isOpenFirstBuild = false;
        this.isLocationOpen = false;
      }
    });

    this.$watch(
        () => router.currentRoute.value.fullPath,
        () => {
          this.isMainPage = router.currentRoute.value.fullPath === '/';
        }
    );
  }
}
</script>
<style scoped>
.router {
  text-decoration: none !important;
}

.router:hover {
  border: 0;
}
</style>