<script setup>

import {computed, onBeforeUnmount, onMounted, ref} from "vue";
import StaticCarousel from "@/components/UI/StaticCarousel.vue";
import store from "@/store";
import axios from "axios";
import ModalComponent from "@/components/UI/ModalComponent.vue";
import FullModalComponent from "@/components/UI/FullModalComponent.vue";
import {useRoute, useRouter} from "vue-router";
import {useHead} from "@vueuse/head";

const filter = ref({
  condition: '',
  type: null,
  make: null,
  minLength: null,
  maxLength: null,
  minPrice: null,
  maxPrice: null,
  minYear: null,
  maxYear: null,
  location: null
});

const currentPage = ref(1);

const yachts = ref(null);

const noYachtsAvailable = ref(false);

const isLoading = ref(true);

const isMob = ref(false);

const criticalWidth = 768;

const route = useRoute();

const router = useRouter();

const countOfPage = ref(20);

const countOfFilters = ref(0);

const sortOptions = [
  {value: 'recentlyUpdatedDescending', text: 'Recently Updated: Newest first'},
  {value: 'recentlyUpdatedAscending', text: 'Recently Updated: Oldest first'},
  {value: 'yearDescending', text: 'Year: Newest first'},
  {value: 'yearAscending', text: 'Year: Oldest first'},
  {value: 'priceDescending', text: 'Price: High to Low'},
  {value: 'priceAscending', text: 'Price: Low to High'},
  {value: 'makeAToZ', text: 'Yacht\'s name: (A to Z)'},
  {value: 'makeZToA', text: 'Yacht\'s name: (Z to A)'}
]

const isOpen = ref(false);
const sortMethod = ref(sortOptions[0]);

const conditions = [
  {value: 'all', label: 'All'},
  {value: 'new', label: 'New'},
  {value: 'used', label: 'Used'}
];

const selectedCondition = ref('all');

const selectCondition = (value) => {
  selectedCondition.value = value;
};

const types = [
  {value: null, label: 'All'},
  {value: 'POWER', label: 'Motorboat'},
  {value: 'SAIL', label: 'Sailboat'}
];

const selectedType = ref(null);

const getFilterRout = () => {
  let result = '';
  for (const key in filter.value) {
    const value = filter.value[key];

    if (!isNullOrEmpty(value)) {
      if (key === 'condition' && value === 'all') {
        continue;
      }
      result += `${key}=${encodeURIComponent(value)}&`;
    }
  }

  if (result.endsWith('&')) {
    result = result.slice(0, -1);
  }

  return result;
};


const isNullOrEmpty = (value) => {
  return !value || value === '';
};

const filterCounting = () => {
  let counting = 0;
  for (const key in filter.value) {
    const value = filter.value[key];

    if (!isNullOrEmpty(value)) {
      if (key === 'condition' && value === 'all') continue
      counting = counting + 1;
    }
  }
  countOfFilters.value = counting;
}

const generateLinkForYacht = (id) => {
  const link = router.resolve({ path: `/yachts/${id}` });
  return link.href;
};

const initFilterByParams = () => {
  filter.value.condition = selectedCondition.value;
  if (selectedType.value == null) {
    filter.value.type = null;
  } else {
    filter.value.type = selectedType.value.value;
  }
  filter.value.minPrice = startPrice.value;
  filter.value.maxPrice = endPrice.value;
  filter.value.minLength = startLength.value;
  filter.value.maxLength = endLength.value;
  filter.value.minYear = startYear.value;
  filter.value.maxYear = endYear.value;
  filter.value.make = yachtInput.value;
  filter.value.location = locationInput.value;
};

const initParamsByFilter = () => {
  if (filter.value.condition) {
    selectedCondition.value = filter.value.condition;
  }
  if (filter.value.type) {
    selectedType.value = types.find(type => type.value === filter.value.type) || null;
  }
  if (filter.value.minPrice) {
    startPrice.value = filter.value.minPrice;
    applyFilterPrice();
  }
  if (filter.value.maxPrice) {
    endPrice.value = filter.value.maxPrice;
    applyFilterPrice();
  }
  if (filter.value.minLength) {
    startLength.value = filter.value.minLength;
    applyFilterLength();
  }
  if (filter.value.maxLength) {
    endLength.value = filter.value.maxLength;
    applyFilterLength();
  }
  if (filter.value.minYear) {
    startYear.value = filter.value.minYear;
    applyFilterYear();
  }
  if (filter.value.maxYear) {
    endYear.value = filter.value.maxYear;
    applyFilterYear();
  }
  if (filter.value.make) {
    yachtInput.value = filter.value.make;
  }
  if (filter.value.location) {
    locationInput.value = filter.value.location;
    locationMenuInput.value = filter.value.location;
  }
};

const isFilterNotEmpty = () => {
  for (const key in filter.value) {
    const value = filter.value[key];

    if (!isNullOrEmpty(value)) {
      if (!(key === 'condition' && value === 'all')) {
        return true;
      }
    }
  }
  return false;
}

const changePage = () => {
  isLoading.value = true;
  initFilterByParams();
  if (currentPage.value === 1) {
    if (sortMethod.value.value === 'recentlyUpdatedDescending') {
      if (isFilterNotEmpty()) {
        router.push(`/yachts/filters/${getFilterRout()}`);
      } else {
        router.push(`/yachts`);
      }
    } else if (!isFilterNotEmpty()) {
      router.push(`/yachts/sort-by/${sortMethod.value.value}`);
    } else {
      router.push(`/yachts/sort-by/${sortMethod.value.value}/filters/${getFilterRout()}`);
    }
  } else {
    if (!isFilterNotEmpty()) {
      router.push(`/yachts/sort-by/${sortMethod.value.value}/page/${currentPage.value}`);
    } else {
      router.push(`/yachts/sort-by/${sortMethod.value.value}/filters/${getFilterRout()}/page/${currentPage.value}`);
    }
  }
  getYachtsForPage();
};


const selectType = (value) => {
  selectedType.value = value;
};


const toggleDropdown = () => {
  isOpen.value = !isOpen.value;
};

const selectOption = (option) => {
  sortMethod.value = option;
  isOpen.value = false;
  isLoading.value = true;
  changePage();
  getYachtsForPage();
};

const closeDropdown = (event) => {
  const dropdown = document.querySelector('.relative.inline-block.text-left');
  if (dropdown && !dropdown.contains(event.target)) {
    isOpen.value = false;
  }
};

const searchByFilters = () => {
  isModalFilterOpen.value = false;
  currentPage.value = 1;
  changePage();
};

const setCharacteristicForPage = () => {
  const sort = route.params.sort;
  const selectedSortOption = sortOptions.find(option => option.value === sort);
  sortMethod.value = selectedSortOption || sortOptions[0];
  const requestFilter = route.params.filters;
  if (!(requestFilter === 'undefined')) {
    initFilterByRoutParam(requestFilter);
  }
  const page = parseInt(route.params.page);
  currentPage.value = isNaN(page) ? 1 : page;
  initParamsByFilter();
};

const initFilterByRoutParam = (filterString) => {
  const params = new URLSearchParams(filterString);
  if (filter.value === null && filterString != null) filter.value = {};
  params.forEach((value, key) => {
    filter.value[key] = value;
  });
}

const getYachtsForPage = async () => {
  filterCounting();
  isLoading.value = true;
  await getNumberPage();
  try {
    const queryFiltersParams = new URLSearchParams(filter.value).toString();
    const response = await axios.get(`https://api.knotzone.com/api/v1/yachts/${sortMethod.value.value}/${queryFiltersParams}/${currentPage.value}`);
    yachts.value = response.data;
    noYachtsAvailable.value = yachts.value.length === 0;
  } catch (error) {
    console.log('Error when receiving yachts: ' + error);
  } finally {
    isLoading.value = false;
  }
};

const getNumberPage = async () => {
  try {
    const queryFiltersParams = new URLSearchParams(filter.value).toString();
    const response = await axios.get(`https://api.knotzone.com/api/v1/yachts/page/count/${queryFiltersParams}`);
    countOfPage.value = response.data;
  } catch (error) {
    console.log('Error when receiving yachts: ' + error);
  } finally {
    isLoading.value = false;
  }
};

const checkScreenSize = () => {
  isMob.value = window.innerWidth <= criticalWidth;
};

const getCondition = (isNew) => (isNew ? 'NEW' : 'USED');

const formatPrice = (value) => (value == null ? 'price is not specified' : value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."));

/* favorite start */

const isFavModalOpen = ref(false);
const selectedYachtForFavorite = ref(null);
const numberOfFavorite = ref();
const getFavorite = computed(() => store.getters.getFavorite);

const closeModal = () => {
  isFavModalOpen.value = false;
}

const clickFavorite = (yacht) => {
  store.commit('editFavorite', yacht);
  isFavModalOpen.value = false;
};

const favorite = (yacht) => {
  let isFav = false;
  if (getFavorite.value) {
    getFavorite.value.forEach(fav => {
      if (fav.yacht != null && fav.yacht.id === yacht.id) {
        isFav = true;
      }
    });
  }
  return isFav;
};

const checkBeforeClickOnFavorite = async (yacht) => {
  const getAuth = computed(() => store.getters.getAuth);
  const getUserId = computed(() => store.getters.getUserId);
  if (getAuth.value && favorite(yacht)) {
    const response = await axios.get(`https://api.knotzone.com/api/v1/favorite/exist/yacht/${yacht.id}/in/${getUserId.value}`);
    if (response.data.number === 0) {
      clickFavorite(yacht);
    } else {
      numberOfFavorite.value = response.data.number;
      isFavModalOpen.value = true;
      selectedYachtForFavorite.value = yacht;
    }
  } else {
    clickFavorite(yacht);
  }
};

/* favorite end */

/* filters start */

const isModalFilterOpen = ref(false);

const yachtInput = ref(null);

const lengthInput = ref(null);

const startLength = ref(null);

const endLength = ref(null);

const isM = ref(true);

const showPriceFields = ref(false);

const priceInput = ref(null);

const startPrice = ref(null);

const endPrice = ref(null);

const showLocationFields = ref(false);

const locationInput = ref(null);

const showLengthFields = ref(false);

const locations = ref([]);

const locationMenuInput = ref(null);

const showYearFields = ref(false);

const yearInput = ref(null);

const startYear = ref(null);

const endYear = ref(null);

const resetFilterPrice = () => {
  startPrice.value = null;
  endPrice.value = null;
  priceInput.value = null;
  showPriceFields.value = false;
};

const resetFilterYear = () => {
  startYear.value = null;
  endYear.value = null;
  yearInput.value = null;
  showYearFields.value = false;
};

const resetFilterLength = () => {
  startLength.value = null;
  endLength.value = null;
  lengthInput.value = null;
  showLengthFields.value = false;
};

const applyFilterLength = () => {
  if (isM.value) {
    if ((startLength.value == null || startLength.value === 0)
        && (endLength.value == null || endLength.value === 0)) {
      lengthInput.value = null;
    } else if (startLength.value == null || startLength.value === 0) {
      lengthInput.value = `To ${endLength.value || ''}m`;
    } else if (endLength.value == null || endLength.value === 0) {
      lengthInput.value = `From ${startLength.value || ''}m`;
    } else {
      lengthInput.value = `${startLength.value || ''}m - ${endLength.value || ''}m`;
    }
  } else {
    if ((startLength.value == null || startLength.value === 0)
        && (endLength.value == null || endLength.value === 0)) {
      lengthInput.value = null;
    } else if (startLength.value == null || startLength.value === 0) {
      lengthInput.value = `To ${endLength.value || ''}ft`;
    } else if (endLength.value == null || endLength.value === 0) {
      lengthInput.value = `From ${startLength.value || ''}ft`;
    } else {
      lengthInput.value = `${startLength.value || ''}ft - ${endLength.value || ''}ft`;
    }
  }
  showLengthFields.value = false;
};

const applyFilterPrice = () => {
  if ((startPrice.value == null || startPrice.value === 0)
      && (endPrice.value == null || endPrice.value === 0)) {
    priceInput.value = null;
  } else if (startPrice.value == null || startPrice.value === 0) {
    priceInput.value = `To ${endPrice.value || ''}€`;
  } else if (endPrice.value == null || endPrice.value === 0) {
    priceInput.value = `From ${startPrice.value || ''}€`;
  } else {
    priceInput.value = `${startPrice.value || ''}€ - ${endPrice.value || ''}€`;
  }
  priceInput.value = formatPrice(priceInput.value);
  showPriceFields.value = false;
};

const applyFilterYear = () => {
  if ((startYear.value == null || startYear.value === 0)
      && (endYear.value == null || endYear.value === 0)) {
    yearInput.value = null;
  } else if (startYear.value == null || startYear.value === 0) {
    yearInput.value = `To ${endYear.value || ''} year`;
  } else if (endYear.value == null || endYear.value === 0) {
    yearInput.value = `From ${startYear.value || ''} year`;
  } else {
    yearInput.value = `${startYear.value || ''} - ${endYear.value || ''} years`;
  }
  showYearFields.value = false;
};

const openLengthFields = () => {
  showLengthFields.value = true;
  showPriceFields.value = false;
  showLocationFields.value = false;
  showYearFields.value = false;
}

const openPriceFields = () => {
  showPriceFields.value = true;
  showLengthFields.value = false;
  showLocationFields.value = false;
  showYearFields.value = false;
}

const openYearFields = () => {
  showYearFields.value = true;
  showPriceFields.value = false;
  showLengthFields.value = false;
  showLocationFields.value = false;
}

const openLocationFields = () => {
  loadLocations();
  locationMenuInput.value = locationInput.value;
  showLocationFields.value = true;
  showPriceFields.value = false;
  showLengthFields.value = false;
  showYearFields.value = false;
}

const chooseLocation = (location) => {
  locationInput.value = location.nameCountry;
  locationMenuInput.value = '';
  locations.value = [];
  showLocationFields.value = false;
};

const loadLocations = async () => {
  try {
    if (locationMenuInput.value == null) locationMenuInput.value = '';
    const data = {
      nameCountry: locationMenuInput.value
    };

    const response = await axios.post(
        `https://api.knotzone.com/api/v1/location/get/like/name`, data);

    locations.value = response.data.locations;
  } catch (error) {
    console.error('Error loading locations: ', error);
  }
};

const clearFilters = () => {
  selectedCondition.value = 'all';
  selectedType.value = null;

  yachtInput.value = null;

  lengthInput.value = null;
  showLengthFields.value = false;
  startLength.value = null;
  endLength.value = null;

  priceInput.value = null;
  showPriceFields.value = false;
  startPrice.value = null;
  endPrice.value = null;

  yearInput.value = null;
  showYearFields.value = false;
  startYear.value = null;
  endYear.value = null;

  locationInput.value = null;
  showLocationFields.value = false;
  locationMenuInput.value = null;
}

const fetchLocations = async () => {
  await loadLocations();
};

const closeModalFilter = () => {
  isModalFilterOpen.value = false;
}

const openModalFilter = () => {
  isModalFilterOpen.value = true;
}

/* filters end */

onMounted(() => {
  useHead({
    title: 'Knotzone - Global Yacht Sales Listing Aggregator',
    meta: [
      {
        name: 'description',
        content: 'Knotzone is a global aggregator of yacht sales listings and a community for yachtsmen. Find all the information on yachts, purchasing, registration, and documentation in one place.',
      },
      {
        property: 'og:type',
        content: 'website',
      },
      {
        property: 'og:title',
        content: 'Knotzone - Global Yacht Sales Listing Aggregator',
      },
      {
        property: 'og:url',
        content: 'https://knotzone.com/',
      },
      {
        property: 'og:image',
        content: 'https://knotzone.com/our-logo.png',
      },
      {
        property: 'og:description',
        content: 'Knotzone is a global aggregator of yacht sales listings and a community for yachtsmen. Find all the information on yachts, purchasing, registration, and documentation in one place.',
      }
    ],
  });
  setCharacteristicForPage();
  getYachtsForPage();
  window.addEventListener('click', closeDropdown);
  checkScreenSize();
  window.addEventListener('resize', checkScreenSize);
});

onBeforeUnmount(() => {
  window.removeEventListener('click', closeDropdown);
  window.removeEventListener('resize', checkScreenSize);
});

// Определяем видимые страницы на основе текущей страницы
const visiblePages = computed(() => {
  const pages = [];
  const minPage = Math.max(1, currentPage.value - 2);
  const maxPage = Math.min(countOfPage.value, currentPage.value + 2);

  for (let i = minPage; i <= maxPage; i++) {
    pages.push(i);
  }

  return pages;
});

// Переключение страницы
const goToPage = (page) => {
  if (page >= 1 && page <= countOfPage.value) {
    currentPage.value = page;
  }

  yachts.value = null;

  changePage();
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

</script>

<template>
  <div class="relative w-full flex space-x-4 items-center mt-3 mb-8 font-knotzone-medium text-sky-900">
    <!--  sort button  -->
    <div class="absolute left-0 inline-block text-left w-full max-w-xs">
      <div
          class="flex items-center px-4 py-2 bg-white border border-gray-300 rounded-lg shadow-sm hover:shadow-md cursor-pointer w-full"
          @click="toggleDropdown"
      >
        <span class="mr-2 text-sky-900 truncate w-full">{{ sortMethod.text }}</span>
        <svg xmlns="http://www.w3.org/2000/svg" class="text-sky-900 h-5 w-5 flex-shrink-0" viewBox="0 0 20 20"
             fill="currentColor">
          <path fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd" />
        </svg>
      </div>
      <div
          v-if="isOpen"
          class="z-50 w-full origin-top-right absolute right-0 mt-2 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      >

        <div class="py-1">
          <button
              v-for="option in sortOptions"
              :key="option.value"
              @click="selectOption(option)"
              class="block text-sky-900 w-full text-left px-4 py-2 font-knotzone-medium hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900"
          >
            {{ option.text }}
          </button>
        </div>
      </div>
    </div>

    <!--  sort filters  -->
    <button
        class="absolute right-0 flex items-center bg-white border border-gray-300 rounded-full shadow-sm hover:shadow-md focus:outline-none"
        @click="openModalFilter"
        :class="{'p-3': isMob, 'px-4 py-2': !isMob, 'rounded-full': isMob, 'rounded-lg': !isMob}"
    >
      <svg
          class="svg-icon"
          style="width: 1em; height: 1em; vertical-align: middle; fill: currentColor; overflow: hidden;"
          viewBox="0 0 1024 1024"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path
            d="M640 288a64 64 0 1 1 0.032-128.032A64 64 0 0 1 640 288z m123.456-96c-14.304-55.04-64-96-123.456-96s-109.152 40.96-123.456 96H128v64h388.544c14.304 55.04 64 96 123.456 96s109.152-40.96 123.456-96H896V192h-132.544zM640 864a64 64 0 1 1 0.032-128.032A64 64 0 0 1 640 864m0-192c-59.456 0-109.152 40.96-123.456 96H128v64h388.544c14.304 55.04 64 96 123.456 96s109.152-40.96 123.456-96H896v-64h-132.544c-14.304-55.04-64-96-123.456-96M384 576a64 64 0 1 1 0.032-128.032A64 64 0 0 1 384 576m0-192c-59.456 0-109.152 40.96-123.456 96H128v64h132.544c14.304 55.04 64 96 123.456 96s109.152-40.96 123.456-96H896v-64H507.456c-14.304-55.04-64-96-123.456-96"
            fill="rgb(12 74 110)"
        />
      </svg>
      <!-- Индикатор с числом -->
      <span
          v-if="countOfFilters > 0"
          class="absolute top-1 right-1 transform translate-x-1/2 -translate-y-1/2 bg-sky-900
           text-white text-xs font-bold rounded-full w-5 h-5 flex items-center justify-center"
      >
        {{ countOfFilters }}
      </span>
      <span v-if="!isMob">Filters</span>
    </button>
  </div>
  <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4">
    <template v-if="isLoading || !yachts">
      <!-- Скелетон-лоадеры -->
      <div v-for="index in 24" :key="index"
           class="rounded-xl shadow-md bg-white relative group overflow-hidden animate-pulse">
        <div class="w-full h-40 bg-gray-200"></div>
        <div class="mt-2 space-y-1 p-3">
          <div class="h-4 bg-gray-200 rounded w-3/4"></div>
          <div class="h-4 bg-gray-200 rounded w-1/2"></div>
          <div class="h-4 bg-gray-200 rounded w-1/4"></div>
        </div>
      </div>
    </template>
    <template v-else>
      <!-- Контент яхт -->
      <div
          v-for="(yacht, index) in yachts"
          :key="index"
          class="rounded-xl shadow-md bg-white relative group overflow-hidden"
      >
        <!-- Изображение яхты -->
        <div class="relative">
          <static-carousel :images="yacht.images" class="w-full h-40 object-cover" />
          <!-- Иконка избранного -->
          <div class="absolute top-1.5 right-1.5 p-0.5 shadow-sm">
            <button @click="checkBeforeClickOnFavorite(yacht)" class="bg-white rounded-lg p-1"
                    :class="favorite(yacht) ? `bg-sky-900` : `bg-sky-100`">
              <img class="h-6 w-6" :src="favorite(yacht) ? '/heart.svg' : '/unHeart.svg'" alt="Favorite"
                   title="Favorite" />
            </button>
          </div>
        </div>
        <a
            :href="generateLinkForYacht(yacht.id)"
            target="_blank"
            rel="noopener noreferrer"
            class="router z-40"
            style="text-decoration: none !important;"
        >
          <!-- Описание яхты -->
          <div class="mt-2 space-y-1 p-3">
            <h3 :title="yacht.name" class="text-base font-knotzone-medium text-gray-800 truncate">{{ yacht.name }}</h3>
            <p class="text-sm font-knotzone-light text-gray-600">
              {{ yacht.nameCountry }}, {{ getCondition(yacht.isNew) }}
            </p>
            <p class="text-sm font-knotzone-light text-gray-600">{{ yacht.nominalLengthM }}m, {{ yacht.year }}</p>
            <p class="text-base font-knotzone-xbold font-bold text-gray-800 mt-1">€{{ formatPrice(yacht.priceEUR) }}</p>
          </div>
        </a>
      </div>
      <div class="fixed bottom-0 left-0 w-full bg-white py-2">
        <div class="flex justify-center items-center">
          <div class="flex items-center space-x-2">
            <!-- Первая страница -->
            <button
                class="px-2 py-1 rounded border hover:bg-gray-200"
                @click="goToPage(1)"
                :disabled="currentPage === 1"
            >
              «
            </button>

            <!-- Предыдущая страница -->
            <button
                class="px-2 py-1 rounded border hover:bg-gray-200"
                @click="goToPage(currentPage - 1)"
                :disabled="currentPage === 1"
            >
              ‹
            </button>

            <!-- Пагинация -->
            <span
                v-for="page in visiblePages"
                :key="page"
                @click="goToPage(page)"
                :class="[ 'px-3 py-1 cursor-pointer rounded', currentPage === page ? 'bg-blue-500 text-white' : 'hover:bg-gray-200' ]"
            >
              {{ page }}
            </span>

            <!-- Следующая страница -->
            <button
                class="px-2 py-1 rounded border hover:bg-gray-200"
                @click="goToPage(currentPage + 1)"
                :disabled="currentPage === countOfPage"
            >
              ›
            </button>

            <!-- Последняя страница -->
            <button
                class="px-2 py-1 rounded border hover:bg-gray-200"
                @click="goToPage(countOfPage)"
                :disabled="currentPage === countOfPage"
            >
              »
            </button>
          </div>
        </div>
      </div>
    </template>
  </div>
  <ModalComponent :is-open="isFavModalOpen"
                  @modal-close="closeModal"
                  @submit.prevent="clickFavorite(selectedYachtForFavorite)"
                  name="first-modal">
    <template #header>
      <div class="text-center w-full">
        <h3>Add group</h3>
      </div>
    </template>
    <template #content>
      <form @submit.prevent="clickFavorite(selectedYachtForFavorite)">
        <p>This yacht has been added to {{ numberOfFavorite }} groups of favorites, do you really want to remove it
          from favorites?</p>
        <div class="flex space-x-2">
          <button
              class="flex-1 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
              type="submit">
            Yes
          </button>
          <button
              class="flex-1 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
              type="button"
              @click="closeModal">
            Cansel
          </button>
        </div>
      </form>
    </template>
    <template #footer>
    </template>
  </ModalComponent>
  <FullModalComponent :is-open="isModalFilterOpen"
                      @modal-close="closeModalFilter"
                      name="first-modal">
    <!-- Header -->
    <template #header>
      <div class="flex items-center justify-between pb-4">
        <button @click="closeModalFilter" class="text-gray-500 hover:text-gray-700">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        <div class="text-sky-900 absolute left-1/2 transform -translate-x-1/2 flex space-x-6 text-center">
          <button
              class="font-knotzone-xbold border-b-2 border-black">
            Yacht
          </button>
        </div>
      </div>
    </template>

    <!-- Content -->
    <template #content>
      <div class="space-y-4 relative text-sky-900 mb-2">
        <!-- Поле Boat condition -->
        <div>
          <label class="block font-bold text-lg mb-2">Boat condition</label>
          <div class="flex w-full rounded-lg border border-gray-300 overflow-hidden">
            <button
                v-for="condition in conditions"
                :key="condition.value"
                @click="selectCondition(condition.value)"
                :class="[
        'flex-1 py-3 text-sm font-medium text-center',
        selectedCondition === condition.value
          ? 'bg-white border border-gray-900'
          : 'bg-gray-100 hover:bg-gray-200'
      ]"
            >
              {{ condition.label }}
            </button>
          </div>
        </div>

        <!-- Поле Boat type -->
        <div>
          <label class="block font-bold text-lg mb-2">Boat type</label>
          <div class="flex w-full rounded-lg border border-gray-300 overflow-hidden">
            <button
                v-for="type in types"
                :key="type.value"
                @click="selectType(type.value)"
                :class="[
        'flex-1 py-3 text-sm font-medium text-center',
        selectedType === type.value
          ? 'bg-white border border-gray-900'
          : 'bg-gray-100 hover:bg-gray-200'
      ]"
            >
              {{ type.label }}
            </button>
          </div>
        </div>

        <!-- Поле Yacht -->
        <div class="flex flex-col hover:bg-gray-100">
          <input
              v-model="yachtInput"
              id="yacht"
              type="text"
              placeholder="Yacht - eg. model, make"
              class="w-full py-3 px-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-sky-700"
          />
        </div>

        <!-- Поле Length -->
        <div class="flex flex-col hover:bg-gray-100">
          <input
              v-if="!showLengthFields"
              v-model="lengthInput"
              id="length"
              type="text"
              placeholder="Length (m)"
              class="w-full py-3 px-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-900"
              @focus="openLengthFields"
          />
          <div v-if="showLengthFields" class="w-full inset-0 flex items-center justify-center z-10">
            <div class="w-full bg-white p-6 rounded-lg shadow-lg">
              <div class="text-sm font-semibold text-gray-700 mb-2">
                Length <span class="text-gray-400">from | to</span>
              </div>

              <div class="flex items-center text-center space-x-2 mb-4">
                <div class="relative flex items-center border">
                  <input
                      v-model="startLength"
                      type="number"
                      min="0"
                      placeholder="0"
                      class="pl-10 pr-2 py-1 w-full text-sm border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-900"
                  />
                  <span class="absolute left-2 text-gray-500 text-sm">M</span>
                </div>

                <span class="text-sm text-gray-500">to</span>

                <div class="relative flex items-center border">
                  <span class="absolute left-2 text-gray-500 text-sm">M</span>
                  <input
                      v-model="endLength"
                      type="number"
                      placeholder="0"
                      min="0"
                      class="pl-10 pr-2 py-1 w-full text-sm border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-900"
                  />
                </div>
              </div>

              <div class="flex space-x-2">
                <button
                    class="w-1/3 py-2 text-sky-900 bg-white border border-sky-900 hover:bg-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-900"
                    @click="resetFilterLength"
                >
                  Reset
                </button>
                <button
                    class="w-2/3 py-2 text-white bg-sky-900 hover:bg-sky-700 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-900"
                    @click="applyFilterLength"
                >
                  Apply Filter
                </button>
              </div>
            </div>
          </div>

        </div>

        <!-- Поле Price -->
        <div class="flex flex-col hover:bg-gray-100">
          <input
              v-if="!showPriceFields"
              v-model="priceInput"
              id="price"
              type="text"
              placeholder="Price in EUR"
              class="w-full py-3 px-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-900"
              @focus="openPriceFields"
          />
          <div v-if="showPriceFields" class="w-full inset-0 flex items-center justify-center z-10">
            <div class="w-full bg-white p-6 rounded-lg shadow-lg">
              <div class="text-sm font-semibold text-gray-700 mb-2">
                Price <span class="text-gray-400">from | to</span>
              </div>

              <div class="flex items-center text-center space-x-2 mb-4">
                <div class="relative flex items-center border">
                  <input
                      v-model="startPrice"
                      type="number"
                      min="0"
                      placeholder="0"
                      class="pl-10 pr-2 py-1 w-full text-sm border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-900"
                  />
                  <span class="absolute left-2 text-gray-500 text-sm">€</span>
                </div>

                <span class="text-sm text-gray-500">to</span>

                <div class="relative flex items-center border">
                  <span class="absolute left-2 text-gray-500 text-sm">€</span>
                  <input
                      v-model="endPrice"
                      type="number"
                      placeholder="0"
                      min="0"
                      class="pl-10 pr-2 py-1 w-full text-sm border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-900"
                  />
                </div>
              </div>

              <div class="flex space-x-2">
                <button
                    class="w-1/3 py-2 text-sky-900 bg-white border border-sky-900 hover:bg-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-900"
                    @click="resetFilterPrice"
                >
                  Reset
                </button>
                <button
                    class="w-2/3 py-2 text-white bg-sky-900 hover:bg-sky-700 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-900"
                    @click="applyFilterPrice"
                >
                  Apply Filter
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Поле Year -->
        <div class="flex flex-col hover:bg-gray-100">
          <input
              v-if="!showYearFields"
              v-model="yearInput"
              id="price"
              type="text"
              placeholder="Year"
              class="w-full py-3 px-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-900"
              @focus="openYearFields"
          />
          <div v-if="showYearFields" class="w-full inset-0 flex items-center justify-center z-10">
            <div class="w-full bg-white p-6 rounded-lg shadow-lg">
              <div class="text-sm font-semibold text-gray-700 mb-2">
                Year <span class="text-gray-400">from | to</span>
              </div>

              <div class="flex items-center text-center space-x-2 mb-4">
                <div class="relative flex items-center border">
                  <input
                      v-model="startYear"
                      type="number"
                      min="0"
                      placeholder="0"
                      class="pl-10 pr-2 py-1 w-full text-sm border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-900"
                  />
                  <span class="absolute left-2 text-gray-500 text-sm"></span>
                </div>

                <span class="text-sm text-gray-500">to</span>

                <div class="relative flex items-center border">
                  <span class="absolute left-2 text-gray-500 text-sm"></span>
                  <input
                      v-model="endYear"
                      type="number"
                      placeholder="0"
                      min="0"
                      class="pl-10 pr-2 py-1 w-full text-sm border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-900"
                  />
                </div>
              </div>

              <div class="flex space-x-2">
                <button
                    class="w-1/3 py-2 text-sky-900 bg-white border border-sky-900 hover:bg-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-900"
                    @click="resetFilterYear"
                >
                  Reset
                </button>
                <button
                    class="w-2/3 py-2 text-white bg-sky-900 hover:bg-sky-700 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-900"
                    @click="applyFilterYear"
                >
                  Apply Filter
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Поле Location -->
        <div class="flex flex-col hover:bg-gray-100">
          <input
              v-if="!showLocationFields"
              v-model="locationInput"
              id="location"
              type="text"
              placeholder="Location"
              class="w-full py-3 px-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-900"
              @focus="openLocationFields"
          />
          <div v-if="showLocationFields">
            <div>
              <input
                  type="text"
                  v-model="locationMenuInput"
                  @input="fetchLocations"
                  class="w-full py-3 px-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-sky-700"
                  placeholder="Name of country"
                  @click.stop
              />

              <div
                  class="mt-2 max-h-72 overflow-y-auto"
              >
                <button
                    v-for="location in locations"
                    :key="location.id"
                    @click="chooseLocation(location)"
                    class="w-full text-left px-3 py-2 border-b border-gray-200 hover:bg-gray-100"
                >
                  {{ location.nameCountry }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <!-- Footer -->
    <template #footer>
      <div class="mt-6 flex justify-between items-center px-4 py-2 border-t">
        <!-- Кнопка "Clear all" слева -->
        <button @click.prevent="clearFilters" class="text-sky-900">Clear all</button>

        <!-- Кнопка "Search" справа с улучшенной иконкой лупы -->
        <button class="flex items-center px-6 py-2 bg-sky-900 text-white rounded-full hover:bg-sky-700 ml-auto"
                @click="searchByFilters">
          <!-- Улучшенная иконка лупы -->
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" class="w-5 h-5 mr-2">
            <path fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1111.46 2.88l4.87 4.87a1 1 0 01-1.42 1.42l-4.87-4.87A6 6 0 012 8z"
                  clip-rule="evenodd" />
          </svg>
          Search
        </button>
      </div>
    </template>
  </FullModalComponent>
</template>

<style scoped>

</style>