<script setup>

import {computed, onBeforeUnmount, onMounted, ref} from "vue";
import StaticCarousel from "@/components/UI/StaticCarousel.vue";
import axios from "axios";
import FullModalComponent from "@/components/UI/FullModalComponent.vue";
import {useRoute, useRouter} from "vue-router";
import {useHead} from "@vueuse/head";

const currentPage = ref(1);

const countOfPage = ref(1);

const yachts = ref(null);

const isLoading = ref(true);

const isMob = ref(false);

const criticalWidth = 768;

const route = useRoute();

const router = useRouter();

const countOfFilters = ref(0);

const loadCatalog = async () => {
  try {
    let page = route.params.page;
    if (!page) {
      page = 1;
    }
    let searchName = route.params.searchName;
    if (!searchName) {
      searchName = 'none';
      countOfFilters.value = 0;
    } else {
      searchModel.value = searchName;
      countOfFilters.value = 1;
    }
    currentPage.value = Number(page);
    const response = await axios.get(`https://api.knotzone.com/api/v1/catalog/get/all/by/${searchName}/page/${page}`);
    yachts.value = response.data.catalog;
    countOfPage.value = response.data.numberOfPage;
  } catch (error) {
    console.error('Error loading catalog:', error);
  } finally {
    isLoading.value = false;
  }
};

const generateLinkForYacht = (id) => {
  const link = router.resolve({ path: `/catalog/yacht/${id}` });
  return link.href;
};

const performSearch = async () => {
  if (searchModel.value !== '' && searchModel.value) {
    await router.push(`/catalog/q/${searchModel.value}`);
  } else {
    await router.push(`/catalog`);
  }
  isModalFilterOpen.value = false;
  await loadCatalog();
}

const checkScreenSize = () => {
  isMob.value = window.innerWidth <= criticalWidth;
};

/* filters start */

const isModalFilterOpen = ref(false);

const searchModel = ref('');


const clearFilters = () => {
  searchModel.value = '';
}

const closeModalFilter = () => {
  isModalFilterOpen.value = false;
}

const openModalFilter = () => {
  isModalFilterOpen.value = true;
}

// Определяем видимые страницы на основе текущей страницы
const visiblePages = computed(() => {
  const pages = [];
  const minPage = Math.max(1, currentPage.value - 2);
  const maxPage = Math.min(countOfPage.value, Number(currentPage.value) + 2);
  for (let i = minPage; i <= maxPage; i++) {
    pages.push(i);
  }

  return pages;
});

const goToPage = async (page) => {
  isLoading.value = true;
  if (page >= 1 && page <= countOfPage.value) {
    currentPage.value = page;
  }

  yachts.value = null;

  if (searchModel.value !== '' && searchModel.value) {
    await router.push(`/catalog/q/${searchModel.value}/page/${currentPage.value}`);
  } else {
    await router.push(`/catalog/page/${currentPage.value}`);
  }
  await loadCatalog();
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};


/* filters end */

onMounted(() => {
  isLoading.value = true;
  loadCatalog();
  checkScreenSize();
  window.addEventListener('resize', checkScreenSize);
  useHead({
    title: `KnotZone - сatalog yachts`,
    meta: [
      {
        name: 'description',
        content: `Knotzone is a global aggregator of yacht sales listings and a community for yachtsmen. Find all the information on yachts, purchasing, registration, and documentation in one place.`,
      },
      {
        property: 'og:type',
        content: 'website',
      },
      {
        property: 'og:title',
        content: `KnotZone - сatalog yachts`,
      },
      {
        property: 'og:url',
        content: `https://knotzone.com/catalog`,
      },
      {
        property: 'og:image',
        content: `https://knotzone.com/our-logo.png`, // Убедитесь, что эта функция доступна
      },
      {
        property: 'og:description',
        content: `Knotzone is a global aggregator of yacht sales listings and a community for yachtsmen. Find all the information on yachts, purchasing, registration, and documentation in one place.`,
      },
    ],
  });
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', checkScreenSize);
});

</script>

<template>
  <div class="relative w-full flex space-x-4 items-center mt-3 mb-8 font-knotzone-medium text-sky-900">
    <!--  filters  -->
    <button
        class="absolute right-0 flex items-center bg-white border border-gray-300 rounded-full shadow-sm hover:shadow-md focus:outline-none"
        @click="openModalFilter"
        :class="{'p-3': isMob, 'px-4 py-2': !isMob, 'rounded-full': isMob, 'rounded-lg': !isMob}"
    >
      <svg
          class="svg-icon"
          style="width: 1em; height: 1em; vertical-align: middle; fill: currentColor; overflow: hidden;"
          viewBox="0 0 1024 1024"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path
            d="M640 288a64 64 0 1 1 0.032-128.032A64 64 0 0 1 640 288z m123.456-96c-14.304-55.04-64-96-123.456-96s-109.152 40.96-123.456 96H128v64h388.544c14.304 55.04 64 96 123.456 96s109.152-40.96 123.456-96H896V192h-132.544zM640 864a64 64 0 1 1 0.032-128.032A64 64 0 0 1 640 864m0-192c-59.456 0-109.152 40.96-123.456 96H128v64h388.544c14.304 55.04 64 96 123.456 96s109.152-40.96 123.456-96H896v-64h-132.544c-14.304-55.04-64-96-123.456-96M384 576a64 64 0 1 1 0.032-128.032A64 64 0 0 1 384 576m0-192c-59.456 0-109.152 40.96-123.456 96H128v64h132.544c14.304 55.04 64 96 123.456 96s109.152-40.96 123.456-96H896v-64H507.456c-14.304-55.04-64-96-123.456-96"
            fill="rgb(12 74 110)"
        />
      </svg>
      <!-- Индикатор с числом -->
      <span
          v-if="countOfFilters > 0"
          class="absolute top-1 right-1 transform translate-x-1/2 -translate-y-1/2 bg-sky-900
           text-white text-xs font-bold rounded-full w-5 h-5 flex items-center justify-center"
      >
        {{ countOfFilters }}
      </span>
      <span v-if="!isMob">Filters</span>
    </button>
  </div>
  <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4">
    <template v-if="isLoading || !yachts">
      <!-- Скелетон-лоадеры -->
      <div v-for="index in 24" :key="index"
           class="rounded-xl shadow-md bg-white relative group overflow-hidden animate-pulse">
        <div class="w-full h-40 bg-gray-200"></div>
        <div class="mt-2 space-y-1 p-3">
          <div class="h-4 bg-gray-200 rounded w-3/4"></div>
          <div class="h-4 bg-gray-200 rounded w-1/2"></div>
          <div class="h-4 bg-gray-200 rounded w-1/4"></div>
        </div>
      </div>
    </template>
    <template v-else>
      <!-- Контент яхт -->
      <div
          v-for="(yacht, index) in yachts"
          :key="index"
          class="rounded-xl shadow-md bg-white relative group overflow-hidden"
      >
        <div class="relative">
          <static-carousel :images="yacht.images"
                           class="w-full h-40 object-cover" />
        </div>
        <a
            :href="generateLinkForYacht(yacht.id)"
            target="_blank"
            rel="noopener noreferrer"
            class="z-40"
            style="text-decoration: none !important;"
        >
          <!-- Описание яхты -->
          <div class="mt-2 space-y-1 p-3">
            <h3 :title="yacht.name" class="text-base font-knotzone-medium text-gray-800 truncate">
              {{ yacht.name }}
            </h3>
            <p class="text-sm font-knotzone-light text-gray-600">
              {{ yacht.laoft }} ft / {{ yacht.laom }} m
            </p>
            <p class="text-sm font-knotzone-light text-gray-600">
              {{ yacht.firstBuilt }}
            </p>
          </div>
        </a>
      </div>
      <div class="fixed bottom-0 left-0 w-full bg-white py-2">
        <div class="flex justify-center items-center">
          <div class="flex items-center space-x-2">
            <!-- Первая страница -->
            <button
                class="px-2 py-1 rounded border hover:bg-gray-200"
                @click="goToPage(1)"
                :disabled="currentPage === 1"
            >
              «
            </button>

            <!-- Предыдущая страница -->
            <button
                class="px-2 py-1 rounded border hover:bg-gray-200"
                @click="goToPage(currentPage - 1)"
                :disabled="currentPage === 1"
            >
              ‹
            </button>

            <!-- Пагинация -->
            <span
                v-for="page in visiblePages"
                :key="page"
                @click="goToPage(page)"
                :class="[ 'px-3 py-1 cursor-pointer rounded', currentPage === page ? 'bg-blue-500 text-white' : 'hover:bg-gray-200' ]"
            >
              {{ page }}
            </span>

            <!-- Следующая страница -->
            <button
                class="px-2 py-1 rounded border hover:bg-gray-200"
                @click="goToPage(currentPage + 1)"
                :disabled="currentPage === countOfPage"
            >
              ›
            </button>

            <!-- Последняя страница -->
            <button
                class="px-2 py-1 rounded border hover:bg-gray-200"
                @click="goToPage(countOfPage)"
                :disabled="currentPage === countOfPage"
            >
              »
            </button>
          </div>
        </div>
      </div>
    </template>
  </div>
  <FullModalComponent :is-open="isModalFilterOpen"
                      @modal-close="closeModalFilter"
                      name="first-modal">
    <!-- Header -->
    <template #header>
      <div class="flex items-center justify-between pb-4">
        <button @click="closeModalFilter" class="text-gray-500 hover:text-gray-700">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        <div class="text-sky-900 absolute left-1/2 transform -translate-x-1/2 flex space-x-6 text-center">
          <button
              class="font-knotzone-xbold border-b-2 border-black">
            Catalog
          </button>
        </div>
      </div>
    </template>

    <!-- Content -->
    <template #content>
      <div class="space-y-4 relative">
        <!-- Поле Yacht -->
        <div class="flex flex-col hover:bg-gray-100">
          <input
              v-model="searchModel"
              id="yacht"
              type="text"
              placeholder="Yacht - eg. model, make"
              class="w-full py-3 px-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-900"
          />
        </div>
      </div>
    </template>

    <!-- Footer -->
    <template #footer>
      <div class="mt-6 flex justify-between items-center px-4 py-2 border-t">
        <!-- Кнопка "Clear all" слева -->
        <button @click.prevent="clearFilters" class="text-sky-900">Clear all</button>

        <!-- Кнопка "Search" справа с улучшенной иконкой лупы -->
        <button
            @click.prevent="performSearch"
            class="flex items-center px-6 py-2 bg-sky-900 text-white rounded-full hover:bg-sky-700 ml-auto">
          <!-- Улучшенная иконка лупы -->
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" class="w-5 h-5 mr-2">
            <path fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1111.46 2.88l4.87 4.87a1 1 0 01-1.42 1.42l-4.87-4.87A6 6 0 012 8z"
                  clip-rule="evenodd" />
          </svg>
          Search
        </button>
      </div>
    </template>
  </FullModalComponent>
</template>

<style scoped>

</style>